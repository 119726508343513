import * as JWT from "jwt-decode";
const auth = () => {
  function setToken(token) {
    document.cookie = "id_token=" + token + ";path=/;";
    document.cookie = "id_token=" + token + ";path=/;domain=.impactgenome.com;";
    document.cookie = "id_token=" + token + ";path=/;domain=.impactgenome.org;";
  }

  function getToken() {
    const cookies = document.cookie.match(
      "(^|[^;]+)\\s*" + "id_token" + "\\s*=\\s*([^;]+)"
    );
    return cookies ? cookies.pop() : "";
  }

  function logout() {
    //document.cookie = 'id_token=;path=/;';
    document.cookie = "id_token=;path=/;";
    document.cookie = "id_token=;path=/;domain=.impactgenome.com;";
    document.cookie = "id_token=;path=/;domain=.impactgenome.org;";
  }

  function isLoggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = getToken();
    return !!token && !isTokenExpired(token);
  }

  function isLoggedInAdmin() {
    // Checks if there is a saved token and it's still valid
    const token = getToken();
    return !!token && isAdmin(token);
  }

  const getDecodedTokenInfo = () => {
    const token = getToken();
    console.log(token);
    if (token !== null) {
      const decoded = JWT(token);
      console.log(decoded);
      return decoded;
    } else {
      return null;
    }
  };

  const isLegacyUser = () => {
    const token = getToken();
    if (token !== null) {
      const decoded = JWT(token);
      return decoded.legacyuser;
    } else {
      return null;
    }
  };

  function getUserId() {
    const token = getToken();

    if (isLoggedIn()) {
      try {
        const decoded = JWT(token);
        return decoded.id;
      } catch (err) {}
    }
  }

  function getRolename() {
    const token = getToken();

    if (isLoggedIn()) {
      try {
        const decoded = JWT(token);
        return decoded.rolename;
      } catch (err) {}
    }
  }

  function isTokenExpired(token) {
    try {
      const decoded = JWT(token);

      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return true; // if something goes wrong decoding token, assume it is expired
    }
  }

  function isAdmin(token) {
    // admins
    var admins = ["Program Reviewer", "IGP Administrators"];
    try {
      const decoded = JWT(token);
      if (
        decoded.exp > Date.now() / 1000 &&
        admins.includes(decoded.rolename)
      ) {
        // Checking if token is expired
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false; // if something goes wrong decoding token, assume it is expired
    }
  }
  return {
    setToken,
    getToken,
    logout,
    getDecodedTokenInfo,
    isLegacyUser,
    isLoggedIn,
    isLoggedInAdmin,
    getUserId,
    getRolename,
  };
};
export default auth;
