import React from "react";
import { Link, useHistory, Prompt } from "react-router-dom";
import useApiRequest from "../hooks/useApiRequest";
import useUnload from "../hooks/useUnload";
import SectionSelect from "./SectionSelect";

import { GlobalNav, Header, Main } from "../templates/Layout";
import { Breadcrumb } from "../elements/Breadcrumb";

import Button from "../elements/Button";
import { Sidebar } from "../templates/Layout";

function Review(props) {
  const {
    cookieData,
    newProgramState,
    secondaryOutcomes,
    sections,
    handleSectionEnable,
    handleCancel,
  } = props;
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  const [isSave, setIsSave] = React.useState(false);
  React.useEffect(() => {
    (() => {
      if (sections[6].disabled) {
        handleSectionEnable(6);
      }
    })();
  }, []);
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const history = useHistory();
  const handleSave = async () => {
    setIsSave(true);
    if (newProgramState.programid) {
      await fetchDataOnDemand({
        reqType: "updateProgram",
        body: {
          ...newProgramState,
        },
      });
      history.push(`/program?programuuid=${newProgramState.programuuid}`, {
        createreport: false,
      });
    } else {
      const response = await fetchDataOnDemand({
        reqType: "addProgram",
        body: {
          ...newProgramState,
        },
      });
      history.push(`/program?programuuid=${response.programuuid}`, {
        createreport: false,
      });
    }
  };
  const handleCreate = async () => {
    const response = await fetchDataOnDemand({
      reqType: "addProgram",
      body: {
        ...newProgramState,
      },
    });
    history.push(`/program?programuuid=${response.programuuid}`, {
      createreport: true,
    });
  };
  return (
    <>
      <Prompt
        message={(params) => {
          return params.pathname.includes("createprogram") || isSave
            ? true
            : "You may have unsaved data. Are you sure you want to leave?";
        }}
      />
      <div className="bg-purple-800 pb-14">
        <GlobalNav cookieData={cookieData} />
        <Header>
          <div className="flex flex-nowrap justify-between w-full items-start">
            <div>
              <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                {props.isEdit ? "Edit Program" : "Add New Program"}
              </h2>

              <div className="text-white">
                Your inventory of social programs or projects.
              </div>
            </div>
            <SectionSelect sections={props.sections} currentSection={6} />
          </div>
        </Header>
      </div>
      <Main>
        <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
          <div className="">Review</div>
          <div className="">Step 7 of 7</div>
        </div>
        <hr className="-mx-6" />

        <div className="w-full mx-auto  px-6 pb-6 mt-6   bg-white">
          <div className="flex flex-col items-center ">
            <div className="w-full max-w-4xl -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block  sm:px-6 lg:px-8 mx-auto w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody>
                      <tr className="bg-white">
                        <td
                          className="px-6 py-4  text-xl leading-5 font-medium"
                          colSpan="2"
                        >
                          {newProgramState.programname}
                        </td>
                      </tr>
                      <tr className="bg-purple-100">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm font-medium leading-5 uppercase text-gray-500 align-top">
                          Program Description
                        </td>
                        <td className="px-6 py-4  text-sm leading-5">
                          {newProgramState.programoverview}
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm font-medium leading-5 uppercase text-gray-500">
                          Operating Location(s)
                        </td>
                        <td className="px-6 py-4  text-sm leading-5">
                          {newProgramState.location
                            .map((location, index) =>
                              index === 0
                                ? location?.name
                                : `, ${location?.name}`
                            )
                            .join("")}
                        </td>
                      </tr>
                      <tr className="bg-purple-100">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm font-medium leading-5 uppercase text-gray-500">
                          Program Classification
                        </td>
                        <td className="px-6 py-4  text-sm leading-5">
                          {newProgramState.classification?.name}
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm font-medium leading-5 uppercase text-gray-500">
                          Program Beneficiary Type
                        </td>
                        <td className="px-6 py-4  text-sm leading-5">
                          {newProgramState.primarybeneficiary?.name}
                        </td>
                      </tr>
                      <tr className="bg-purple-100">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm font-medium leading-5 uppercase text-gray-500">
                          Primary Outcome
                        </td>
                        <td className="px-6 py-4  text-sm leading-5">
                          {newProgramState.primaryoutcome?.name}
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm font-medium leading-5 uppercase text-gray-500">
                          Secondary Outcomes
                        </td>
                        <td className="px-6 py-4  text-sm leading-5">
                          {secondaryOutcomes.length ? (
                            secondaryOutcomes
                              .map((outcome, index) =>
                                index === 0 ? outcome.name : `, ${outcome.name}`
                              )
                              .join("")
                          ) : (
                            <em>No secondary outcomes have been selected</em>
                          )}
                        </td>
                      </tr>
                      <tr className="bg-purple-100">
                        <td className="px-6 py-4 whitespace-no-wrap text-sm font-medium leading-5 uppercase text-gray-500">
                          Promote to Donors
                        </td>
                        <td className="px-6 py-4 -no-wrap text-sm leading-5">
                          {newProgramState.ispublic
                            ? "Yes, promote to donors"
                            : "No, do not promote to donors"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <hr className="border w-full sm:px-6 lg:px-8 mx-auto" /> */}
              <hr className="border my-4" />
              <div className="sm:px-6 lg:px-8 mx-auto w-full">
                <p>
                  Next, please complete an Impact Report for this program to:
                </p>
                <ul className="list-disc">
                  <li className="ml-5">Become Impact Verified</li>
                  <li className="ml-5">
                    Receive your free Impact Genome Scorecard
                  </li>
                  <li className="ml-5">
                    Appear on the IGP Nonprofit Directory to be discoverd by new
                    donors (ensure "Promote to donors" is selected)
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <hr className="border mt-10" />
          <div className="flex justify-between my-6">
            <Button
              color="gray"
              className="justify-self-start text-right w-48"
              onClick={() => handleCancel()}
            >{`Cancel`}</Button>
            <div className="flex justify-end">
              <Link to="/createprogram/selectsecondaryoutcomes">
                <Button
                  color="blue"
                  className="text-right w-40 mr-8"
                >{`Previous`}</Button>
              </Link>
              <Button
                color={newProgramState.programid ? "green" : "purple"}
                className="text-right w-40 mr-8"
                onClick={() => handleSave()}
              >
                Save Program
              </Button>
              {!newProgramState.programid && (
                <Button
                  color="green"
                  className="text-right w-56"
                  onClick={() => handleCreate()}
                >{`Save & Create Report`}</Button>
              )}
            </div>
          </div>
          {/* <div className="flex w-full justify-end my-6">
            <Link to="/createprogram/selectsecondaryoutcomes">
              <Button
                color="gray"
                className="text-right w-40 mr-8"
              >{`Previous`}</Button>
            </Link>
            <Button
              color="blue"
              className="text-right w-40 mr-8"
              onClick={() => handleSave()}
            >
              Save Program
            </Button>
            {!newProgramState.programid && (
              <Button
                color="green"
                className="text-right w-56"
                onClick={() => handleCreate()}
              >{`Save & Create Report`}</Button>
            )}
          </div> */}
        </div>
      </Main>
    </>
  );
}

export default Review;
