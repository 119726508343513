import React from "react";
import { Text, View } from "@react-pdf/renderer";

import styles from "../../styles";

const Location = (props) => {
  const { object, empty } = props;
  const answer = object.responsevalue;
  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Please provide the region(s) and postal code(s) in which your program currently operates."}
      </Text>
      <View style={{ flexDirection: "row", flexWrap: "true" }}>
        {empty ? (
          <Text></Text>
        ) : answer ? (
          answer.map((country) => {
            return (
              <View>
                <Text wrap>
                  {country.country.name && country.regions
                    ? country.country.name +
                      " (" +
                      country.regions.map((region) => region.name).join(", ") +
                      ")"
                    : ""}
                </Text>
                <Text wrap>
                  {country.postal ? "Postal Codes: " + country.postal : ""}
                </Text>
              </View>
            );
          })
        ) : (
          <Text></Text>
        )}
      </View>
    </>
  );
};

export default Location;
