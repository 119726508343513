import React from "react";

function Button(props) {
  const {
    variant = "secondary",
    onClick = () => {},
    size = "md",
    children,
    className = "",
    color = "gray",
    disabled = false,
  } = props;

  return (
    <div className={className}>
      <span className="inline-flex rounded-md shadow-sm w-full">
        {disabled ? (
          <button
            type="button"
            className={`opacity-50 cursor-not-allowed inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-${color}-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${className}`}
          >
            {children}
          </button>
        ) : (
          <button
            type="button"
            className={` w-full mx-auto inline-flex items-center px-6 py-2 border border-transparent text-base justify-center leading-6 font-medium rounded text-white bg-${color}-500 hover:bg-${color}-400 focus:outline-none focus:border-${color}-600 focus:shadow-outline-gray active:bg-${color}-600 transition ease-in-out duration-150`}
            onClick={onClick}
          >
            {children}
          </button>
        )}
      </span>
    </div>
  );
}
//${colors[variant]}
export default Button;
