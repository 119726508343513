import React from "react";
import { Link, Prompt, useHistory, useRouteMatch } from "react-router-dom";
import { Transition } from "@headlessui/react";
import useApiRequest from "../hooks/useApiRequest";
import useUnload from "../hooks/useUnload";

import { GlobalNav, Header, Main } from "../templates/Layout";
import SectionSelect from "./SectionSelect";
import { ModalBody, ModalButton, ModalParent } from "../elements/Modal";
import Button from "../elements/Button";
import LoadingIcon from "../elements/LoadingIcon";

function ProgramClassification({
  classificationTypes,
  handleTypeSelect,
  selectedClassificationId,
}) {
  return (
    <div className="flex flex-wrap w-full">
      {classificationTypes &&
        classificationTypes.map((type, index) => {
          const selected =
            type.programclassificationid === selectedClassificationId;
          return (
            <React.Fragment key={index}>
              <div
                className={`${
                  selected
                    ? "border-2 border-blue-500 bg-blue-500 bg-opacity-5"
                    : "border"
                } shadow-sm rounded w-full md:w-72 mr-4 mt-2 mb-2 p-4 cursor-pointer relative`}
                onClick={() => handleTypeSelect("classification", type)}
              >
                {selected && (
                  <div className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 rounded-full bg-blue-500 flex justify-center items-center">
                    <svg
                      className="inline-block"
                      width="11"
                      height="9"
                      fill="#4D93B8"
                      viewBox="0 0 11 9"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2271 2.17576L10.3257 2.11685L9.16378 0.954468L4.10145 5.99704L1.83607 3.73166L0.674194 4.87424L4.10138 8.30143L10.2271 2.17576Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
                <div className="font-semibold">{type.name}</div>
                <div className="text-xs">{type.description}</div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
}

function ProgramBeneficiary({
  beneficiaryTypes,
  handleTypeSelect,
  selectedBeneficiaryId,
  selectedClassificationId,
  showAlert,
}) {
  return (
    <div className="flex flex-wrap w-full">
      {beneficiaryTypes &&
        beneficiaryTypes.map((type, index) => {
          const selected = type.beneficiarytypeid === selectedBeneficiaryId;
          const isDisabled = () => {
            if (
              selectedClassificationId === 1 &&
              type.beneficiarytypeid === 3
            ) {
              if (selectedBeneficiaryId === 3) {
                handleTypeSelect("primarybeneficiary", null);
              }
              return true;
            } else if (
              selectedClassificationId === 4 &&
              type.beneficiarytypeid !== 3
            ) {
              if (selectedBeneficiaryId && selectedBeneficiaryId !== 3) {
                handleTypeSelect("primarybeneficiary", null);
              }
              return true;
            } else return false;
          };

          return (
            <React.Fragment key={index}>
              <div
                className={`
                ${
                  isDisabled()
                    ? "border bg-disabled text-gray-400 cursor-not-allowed"
                    : "border"
                }
                ${
                  selected
                    ? "border-2 border-blue-500 bg-blue-500 bg-opacity-5"
                    : "border"
                } shadow-sm rounded w-full md:w-72 mr-4 mt-2 mb-2 p-4 cursor-pointer relative`}
                onClick={() =>
                  !isDisabled()
                    ? handleTypeSelect("primarybeneficiary", type)
                    : showAlert()
                }
              >
                {selected && (
                  <div className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 rounded-full bg-blue-500 flex justify-center items-center">
                    <svg
                      className="inline-block"
                      width="11"
                      height="9"
                      fill="#4D93B8"
                      viewBox="0 0 11 9"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2271 2.17576L10.3257 2.11685L9.16378 0.954468L4.10145 5.99704L1.83607 3.73166L0.674194 4.87424L4.10138 8.30143L10.2271 2.17576Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
                <div className="font-semibold">{type.name}</div>
                <div className="text-xs">{type.description}</div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
}

function Classification(props) {
  const {
    cookieData,
    newProgramState,
    setNewProgramState,
    sections,
    handleSectionEnable,
    handleCancel,
  } = props;
  const [isAlert, setIsAlert] = React.useState(false);

  const { state: beneficiaryTypes } = useApiRequest({
    reqType: "getBeneTypes",
  });
  const { state: classificationTypes } = useApiRequest({
    reqType: "getClassifications",
  });
  React.useEffect(() => {
    (() => {
      if (sections[1].disabled) {
        handleSectionEnable(1);
      }
    })();
  }, []);
  let history = useHistory();
  let { path, url } = useRouteMatch();
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const handleTypeSelect = (type, value) => {
    setNewProgramState({ [type]: value });
  };
  console.log(!!newProgramState.classification);
  return (
    <>
      <Prompt
        message={(params) => {
          return params.pathname.includes("createprogram")
            ? true
            : "You may have unsaved data. Are you sure you want to leave?";
        }}
      />
      <div className="bg-purple-800 pb-14">
        <GlobalNav cookieData={cookieData} />
        <Header>
          <div className="flex flex-nowrap justify-between w-full items-start">
            <div>
              <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                {props.isEdit ? "Edit Program" : "Add New Program"}
              </h2>

              <div className="text-white">
                Your inventory of social programs or projects.
              </div>
            </div>
            <SectionSelect sections={props.sections} currentSection={1} />
          </div>
        </Header>
      </div>
      <Main>
        <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
          <div className="">Classification</div>
          <div className="">Step 2 of 7</div>
        </div>
        <hr className="-mx-6" />
        {/* <div className="w-full mx-auto px-10"> */}
        <div className="">
          {beneficiaryTypes.data && classificationTypes.data ? (
            <div className="w-full mx-auto  px-10 pb-6   bg-white">
              <div className="max-w-2xl mb-4 pt-8">
                Next, please select the best descriptions of your program
                classification and primary beneficiary type.
              </div>
              <div className=" font-bold mb-1 pt-6">
                Primary Program Classification
              </div>
              <div className="max-w-2xl">
                <p className="mb-4">
                  How would you best classify this program? We understand that
                  many programs are multi-faceted, so please pick one that best
                  describes the program.
                </p>
              </div>
              <div className="mb-6">
                <ProgramClassification
                  classificationTypes={classificationTypes.data}
                  selectedClassificationId={
                    newProgramState.classification?.programclassificationid
                  }
                  handleTypeSelect={handleTypeSelect}
                />
              </div>

              <hr className=" border" />
              <Transition
                show={!!newProgramState.classification}
                enter="transition-opacity ease-linear duration-700"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div>
                  <div className=" font-bold mb-1 mt-6">
                    Primary Program Beneficiary Type
                  </div>
                  <div className="max-w-2xl">
                    <p className="">
                      Which best describes the direct beneficiaries of your
                      program?
                    </p>
                    <p className="mb-4 text-sm italic">
                      Beneficary types that do not correspond with your selected
                      program classification are disabled.
                    </p>
                  </div>
                  <div className="mb-6">
                    <ProgramBeneficiary
                      beneficiaryTypes={beneficiaryTypes.data}
                      selectedClassificationId={
                        newProgramState.classification?.programclassificationid
                      }
                      selectedBeneficiaryId={
                        newProgramState.primarybeneficiary?.beneficiarytypeid
                      }
                      handleTypeSelect={handleTypeSelect}
                      showAlert={() => setIsAlert(true)}
                    />
                  </div>
                  <hr className="mt-10 border" />
                </div>
              </Transition>

              <div className="flex justify-between my-6">
                <Button
                  color="gray"
                  className="justify-self-start text-right w-48"
                  onClick={() => handleCancel()}
                >{`Cancel`}</Button>
                <div className="flex justify-end">
                  <Link to="/createprogram/overview">
                    <Button
                      color="blue"
                      className="text-right w-48 mr-8"
                    >{`Previous`}</Button>
                  </Link>

                  <Button
                    color="green"
                    className="text-right w-48"
                    onClick={() => history.push("/createprogram/impactareas")}
                    disabled={
                      !newProgramState.primarybeneficiary ||
                      !newProgramState.classification
                    }
                  >{`Save & Continue`}</Button>
                </div>
              </div>
            </div>
          ) : (
            <LoadingIcon />
          )}
          <ModalParent isOpen={isAlert}>
            <ModalBody alert title={`Option not available`}>
              <p className="mx-8">
                The option you clicked is not available. Please select another.
              </p>
            </ModalBody>
            <div className="flex flex-no-wrap justify-center">
              <ModalButton text="Close" onClick={() => setIsAlert(false)} />
            </div>
          </ModalParent>
        </div>
        {/* </div> */}
      </Main>
    </>
  );
}

export default Classification;
