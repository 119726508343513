import React from "react";
import { Link, useHistory, useRouteMatch, Prompt } from "react-router-dom";
import { Transition } from "@headlessui/react";
import useApiRequest from "../hooks/useApiRequest";
import useUnload from "../hooks/useUnload";
import { GlobalNav, Header, Main } from "../templates/Layout";
import { Breadcrumb } from "../elements/Breadcrumb";
import FullProgramBreadcrumb from "./FullProgramBreadcrumb";
import SectionSelect from "./SectionSelect";

import Input from "../elements/Input";
import Textarea from "../elements/Textarea";
import Dropdown from "../elements/Dropdown";
import Button from "../elements/Button";
import { Sidebar } from "../templates/Layout";
import LoadingIcon from "../elements/LoadingIcon";
import ImpactAreaIcon from "./ImpactAreaIcon";

function ImpactAreaElements({
  selectedImpactAreas,
  setSelectedImpactAreas,
  impactAreas,
}) {
  return (
    <div className="flex flex-wrap w-full">
      {impactAreas.map((impactArea, index) => {
        const selected = selectedImpactAreas.some(
          (selectedImpactArea) =>
            impactArea.impactareaid === selectedImpactArea.impactareaid
        );
        return (
          <React.Fragment key={index}>
            <div
              className={`${
                selected
                  ? "border-2 border-blue-500 bg-blue-500 bg-opacity-5"
                  : "border"
              } shadow-sm rounded w-full sm:w-5/12 md:w-56 mr-4 mt-2 mb-2 p-4 cursor-pointer relative`}
              onClick={() => setSelectedImpactAreas(impactArea)}
            >
              {selected && (
                <div className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 rounded-full bg-blue-500 flex justify-center items-center">
                  <svg
                    className="inline-block"
                    width="11"
                    height="9"
                    fill="#4D93B8"
                    viewBox="0 0 11 9"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2271 2.17576L10.3257 2.11685L9.16378 0.954468L4.10145 5.99704L1.83607 3.73166L0.674194 4.87424L4.10138 8.30143L10.2271 2.17576Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              <ImpactAreaIcon
                className="mb-4"
                size="2x"
                impactArea={impactArea.name}
              />
              <div className="font-semibold">{impactArea.name}</div>
              <div className="text-xs">{impactArea.description}</div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

function ImpactAreas(props) {
  const {
    cookieData,
    selectedImpactAreas,
    setSelectedImpactAreas,
    sections,
    handleSectionEnable,
    handleCancel,
  } = props;
  const { state: impactAreas } = useApiRequest({
    reqType: "getImpactAreas",
  });
  React.useEffect(() => {
    (() => {
      if (sections[2].disabled) {
        handleSectionEnable(2);
      }
    })();
  }, []);
  let history = useHistory();
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  let { path, url } = useRouteMatch();

  return (
    <>
      <Prompt
        message={(params) => {
          return params.pathname.includes("createprogram")
            ? true
            : "You may have unsaved data. Are you sure you want to leave?";
        }}
      />
      <div className="bg-purple-800 pb-14">
        <GlobalNav cookieData={cookieData} />
        <Header>
          <div className="flex flex-nowrap justify-between w-full items-start">
            <div>
              <div>
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                  {props.isEdit ? "Edit Program" : "Add New Program"}
                </h2>

                <div className="text-white">
                  Your inventory of social programs or projects.
                </div>
              </div>
            </div>
            <SectionSelect sections={props.sections} currentSection={2} />
          </div>
        </Header>
      </div>
      <Main>
        <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
          <div className="">Impact Areas</div>
          <div className="">Step 3 of 7</div>
        </div>
        <hr className="-mx-6" />
        {impactAreas.data ? (
          <div className="">
            <div className="w-full mx-auto  px-10    bg-white">
              <div className="max-w-2xl font-bold mb-2 pt-8">
                In which areas does your program produce impact?
              </div>
              <div className="max-w-2xl mb-1 pt-2">
                <ul className=" list-disc mb-4">
                  <li className="ml-5">
                    Programs can aim to produce many short and long-term outcome
                  </li>
                  <li className="ml-5">
                    Please identify the one outcome that your program is best
                    designed to achieve for your beneficiaries within a typical
                    year/program cycle
                  </li>
                </ul>
              </div>

              <div className="mb-6">
                <ImpactAreaElements
                  selectedImpactAreas={selectedImpactAreas}
                  setSelectedImpactAreas={setSelectedImpactAreas}
                  impactAreas={impactAreas.data}
                />
              </div>

              <hr className=" -mx-16 border" />
            </div>
            <div className="flex justify-between my-6">
              <Button
                color="gray"
                className="justify-self-start text-right w-48"
                onClick={() => handleCancel()}
              >{`Cancel`}</Button>
              <div className="flex justify-end">
                <Link to="/createprogram/classification">
                  <Button
                    color="blue"
                    className="text-right w-48 mr-8"
                  >{`Previous`}</Button>
                </Link>

                <Button
                  color="green"
                  className="text-right w-48"
                  onClick={() => history.push("/createprogram/selectoutcomes")}
                  disabled={!selectedImpactAreas.length}
                >{`Save & Continue`}</Button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingIcon />
        )}
      </Main>
    </>
  );
}

export default ImpactAreas;
