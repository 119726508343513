import React from "react";
import { Link } from "react-router-dom";
import chevronRight from "../assets/icons/chevronRight.svg";

function ProgramListItem({ program, handleClick }) {
  return (
    <div className="flex w-full justify-between p-6">
      <div className="w-11/12">
        <div className="text-xl font-bold">{program.programname}</div>
        <div className="mt-2">{program.programoverview}</div>
        <div className="w-full mt-4 flex flex-nowrap justify-start">
          <div className="w-1/3">
            <div className="text-xs uppercase text-gray-500 tracking-widest">
              primary classification
            </div>
            <div className="">{program.classification.name}</div>
          </div>
          <div className="w-1/3">
            <div className="text-xs uppercase text-gray-500 tracking-widest">
              primary Beneficiary
            </div>
            <div className="">{program.primarybeneficiary.name}</div>
          </div>
          <div className="w-1/3">
            <div className="text-xs uppercase text-gray-500 tracking-widest">
              primary outcome
            </div>
            <div className="">{program.primaryoutcome.outcome.name}</div>
          </div>
        </div>
        <div className="w-full mt-6 flex flex-nowrap justify-start">
          <div className="w-1/3">
            <div className="text-xs uppercase text-gray-500 tracking-widest">
              Operating Location(s)
            </div>
            <div className=" pr-1">
              {program.location
                .map((location, index) =>
                  index === 0 ? location.name : `, ${location.name}`
                )
                .join("")}
            </div>
          </div>
          <div className="w-1/3">
            <div className="text-xs uppercase text-gray-500 tracking-widest">
              Discoverability
            </div>
            <div className="">{program.ispublic ? "Public" : "Private"}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <Link to={`/program?programuuid=${program.programuuid}`}>
          <div className="text-xl text-bold">
            <img src={chevronRight} alt="" />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ProgramListItem;
