import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../../styles";

const PrimaryReach = (props) => {
  const { object, empty } = props;
  const answer = object.responsevalue;

  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Please complete the following impact statement by inserting your reach and outcomes."}
      </Text>
      {empty ? (
        <Text></Text>
      ) : answer ? (
        answer.map((input) => (
          <Text>
            {input.inputValues.achievednumber +
              " out of " +
              input.inputValues.outofnumber +
              " " +
              input.inputValues.beneficiary.name}
          </Text>
        ))
      ) : (
        <Text></Text>
      )}
    </>
  );
};

export default PrimaryReach;
