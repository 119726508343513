import React from "react";
import {
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
  Prompt,
} from "react-router-dom";
import Overview from "../components/Overview";
import Classification from "../components/Classification";
import ImpactAreas from "../components/ImpactAreas";
import SelectOutcomes from "../components/SelectOutcomes";
import SelectPrimaryOutcomes from "../components/SelectPrimaryOutcome";
import SelectSecondaryOutcomes from "../components/SelectSecondaryOutcomes";
import Review from "../components/Review";

function CreateProgram(props) {
  const { cookieData } = props;
  let { url, path } = useRouteMatch();
  let { createProgramPage } = useParams();
  const location = useLocation();
  const history = useHistory();

  const intialState = () => {
    if (location.state) {
      return {
        ...location.state,
        userid: cookieData.userid,
        selectedoutcomes: location.state.selectedoutcomes.some(
          (outcome) =>
            outcome.outcomeid === location.state.primaryoutcome.outcomeid
        )
          ? location.state.selectedoutcomes
          : [...location.state.selectedoutcomes, location.state.primaryoutcome],
      };
    }

    return {
      programname: "",
      programoverview: "",
      organizationid: cookieData.organizations[0].organizationid,
      organizationuuid: cookieData.organizations[0].organizationuuid,
      primaryoutcome: null,
      location: [],
      classification: null,
      primarybeneficiary: null,
      ispublic: true,
      selectedoutcomes: [],
      secondaryoutcomes: [],
      userid: cookieData.userid,
    };
  };

  function getSelectedImpactAreas(data) {
    let impactareas = [];
    if (data.selectedoutcomes.length) {
      data.selectedoutcomes.forEach((outcome) => {
        if (
          !impactareas.some(
            (impactArea) => impactArea.impactareaid === outcome.impactareaid
          )
        ) {
          impactareas.push({
            name: outcome.impactareaname,
            impactareaid: outcome.impactareaid,
          });
        }
      });
    }
    if (
      !impactareas.some(
        (impactArea) =>
          impactArea.impactareaid === data.primaryoutcome.impactareaid
      )
    ) {
      impactareas.push({
        name: data.primaryoutcome.impactareaname,
        impactareaid: data.primaryoutcome.impactareaid,
      });
    }
    return impactareas;
  }

  const [newProgramState, setNewProgramState] = React.useState(intialState());
  const [selectedImpactAreas, setSelectedImpactAreas] = React.useState(
    location.state ? getSelectedImpactAreas(location.state) : []
  );
  const [isEdit, setIsEdit] = React.useState(location.state ? true : false);
  const [sections, setSections] = React.useState([
    {
      id: 0,
      text: "Program Overview",
      link: `/createprogram/overview`,
      disabled: false,
    },
    {
      id: 1,
      text: "Classification",
      link: `/createprogram/classification`,
      disabled: location.state ? false : true,
    },
    {
      id: 2,
      text: "Impact Areas",
      link: `/createprogram/impactareas`,
      disabled: location.state ? false : true,
    },
    {
      id: 3,
      text: "Select Outcomes",
      link: `/createprogram/selectoutcomes`,
      disabled: location.state ? false : true,
    },
    {
      id: 4,
      text: "Select Primary Outcome",
      link: `/createprogram/selectprimaryoutcome`,
      disabled: location.state ? false : true,
    },
    {
      id: 5,

      text: "Select Secondary Outcomes",
      link: `/createprogram/selectsecondaryoutcomes`,
      disabled: location.state ? false : true,
    },
    {
      id: 6,
      text: "Review",
      link: `/createprogram/review`,
      disabled: location.state ? false : true,
    },
  ]);
  const handleSectionEnable = (id) => {
    let updatedObj = sections.find((section) => section.id === id);

    setSections((prev) => [
      ...prev.filter((section) => section.id !== id),
      { ...updatedObj, disabled: false },
    ]);
  };

  const handleCancel = () => {
    if (newProgramState.programuuid) {
      history.push(`/program?programuuid=${newProgramState.programuuid}`);
    } else {
      history.push("/organization");
    }
  };
  const createProgramProps = {
    cookieData,
    newProgramState,
    isEdit,
    sections,
    setNewProgramState: (e) =>
      setNewProgramState((prev) => ({ ...prev, ...e })),
    handleCancel,
  };

  switch (createProgramPage) {
    case "overview": {
      return (
        <Overview {...props} {...createProgramProps} sections={sections} />
      );
    }
    case "classification": {
      return (
        <Classification
          {...props}
          {...createProgramProps}
          sections={sections.sort((a, b) => a.id - b.id)}
          handleSectionEnable={(id) => handleSectionEnable(id)}
        />
      );
    }
    case "impactareas": {
      return (
        <ImpactAreas
          {...props}
          {...{
            cookieData,
            selectedImpactAreas,
            isEdit,

            setSelectedImpactAreas: (e) =>
              selectedImpactAreas.some(
                (impactArea) => impactArea.impactareaid === e.impactareaid
              )
                ? setSelectedImpactAreas((prev) =>
                    prev.filter(
                      (impactArea) => impactArea.impactareaid !== e.impactareaid
                    )
                  )
                : setSelectedImpactAreas((prev) => [...prev, e]),
          }}
          sections={sections.sort((a, b) => a.id - b.id)}
          handleSectionEnable={(id) => handleSectionEnable(id)}
          handleCancel={handleCancel}
        />
      );
    }
    case "selectoutcomes": {
      return (
        <SelectOutcomes
          {...props}
          {...{
            cookieData,
            selectedImpactAreas,
            selectedOutcomes: newProgramState.selectedoutcomes,
            isEdit,

            setSelectedOutcomes: (e) =>
              newProgramState.selectedoutcomes.some(
                (outcome) => outcome.outcomeid === e.outcomeid
              )
                ? setNewProgramState((prev) => ({
                    ...prev,
                    selectedoutcomes: prev.selectedoutcomes.filter(
                      (outcome) => outcome.outcomeid !== e.outcomeid
                    ),
                  }))
                : setNewProgramState((prev) => ({
                    ...prev,
                    selectedoutcomes: [...prev.selectedoutcomes, e],
                  })),
          }}
          sections={sections.sort((a, b) => a.id - b.id)}
          handleSectionEnable={(id) => handleSectionEnable(id)}
          handleCancel={handleCancel}
        />
      );
    }
    case "selectprimaryoutcome": {
      return (
        <SelectPrimaryOutcomes
          {...props}
          {...createProgramProps}
          selectedOutcomes={newProgramState.selectedoutcomes}
          sections={sections.sort((a, b) => a.id - b.id)}
          handleSectionEnable={(id) => handleSectionEnable(id)}
        />
      );
    }
    case "selectsecondaryoutcomes": {
      return (
        <SelectSecondaryOutcomes
          {...props}
          {...{
            cookieData,
            selectedOutcomes: newProgramState.selectedoutcomes,
            primaryOutcome: newProgramState.primaryoutcome,
            secondaryOutcomes: newProgramState.secondaryoutcomes,
            isEdit,

            setSecondaryOutcomes: (e) =>
              newProgramState.secondaryoutcomes.some(
                (outcome) => outcome.outcomeid === e.outcomeid
              )
                ? setNewProgramState((prev) => ({
                    ...prev,
                    secondaryoutcomes: prev.secondaryoutcomes.filter(
                      (outcome) => outcome.outcomeid !== e.outcomeid
                    ),
                  }))
                : setNewProgramState((prev) => ({
                    ...prev,
                    secondaryoutcomes: [...prev.secondaryoutcomes, e],
                  })),
          }}
          sections={sections.sort((a, b) => a.id - b.id)}
          handleSectionEnable={(id) => handleSectionEnable(id)}
          handleCancel={handleCancel}
        />
      );
    }
    case "review": {
      return (
        <Review
          {...props}
          {...{
            cookieData,
            isEdit,
            secondaryOutcomes: newProgramState.secondaryoutcomes,
            newProgramState,
          }}
          sections={sections.sort((a, b) => a.id - b.id)}
          handleSectionEnable={(id) => handleSectionEnable(id)}
          handleCancel={handleCancel}
        />
      );
    }
  }
}

export default CreateProgram;
