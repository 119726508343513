import React from "react";
import { View, Text } from "@react-pdf/renderer";

import styles from "../../styles";

const PrimaryOutcome = (props) => {
  const { object, empty } = props;
  const answer = object.responsevalue;
  var outcomes = answer ? answer : [];

  outcomes.sort((a, b) => {
    if (a > b) {
      return -1;
    } else {
      return 1;
    }
  });
  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Which of the following are the highest priority outcome(s) for your program? You will be asked to report results for these outcomes, and they will appear on your Impact Genome® Scorecard. Select up to 5."}
      </Text>
      <View>
        {empty ? (
          <Text></Text>
        ) : outcomes ? (
          outcomes.map((outcome, i) => {
            return (
              <Text>
                {`${i + 1}. ${
                  outcome.impactarea
                    ? outcome.impactarea.impactname
                    : outcome.impactareaname
                }, ${
                  outcome.focusarea
                    ? outcome.focusarea.name
                    : outcome.genomename
                }, ${outcome.outcome ? outcome.outcome.name : outcome.name}`}
              </Text>
            );
          })
        ) : (
          <Text></Text>
        )}
      </View>
    </>
  );
};

export default PrimaryOutcome;
