import React from "react";
import DatePicker from "react-datepicker";
import "./dateRangeStyles.scss";
import { format } from "date-fns";

const DateRange = (props) => {
  const { value, handleChange, placeholder = "Select a date" } = props;

  const dateFormat = ["MM/dd/yyyy"];

  return (
    <div>
      <DatePicker
        selected={value ? Date.parse(value) : ""}
        onChange={(date) => {
          handleChange(format(new Date(date), "MM/dd/yyyy"));
        }}
        dateFormat={dateFormat}
        // maxDate={}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholder}
      />
    </div>
  );
};

export default DateRange;
