import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useApiRequest from '../hooks/useApiRequest';
import auth from '../helpers/auth';
import igpLogo from '../assets/icons/igp_logo-title.png';

function Login(props) {
  const [loginCredentials, setLoginCredentials] = React.useState({
    email: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = React.useState('');
  const history = useHistory();
  const location = useLocation();
  const { setToken } = auth();
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage('');
    const response = await fetchDataOnDemand({
      reqType: 'login',
      body: loginCredentials
    });
    if (!response.token) {
      setErrorMessage('Incorrect username or password');
      return;
    }
    setToken(response.token);
    if (props.redirect) {
      history.push(props.redirect);
    } else {
      history.push('/organization');
    }
  };

  return (
    <div className="min-h-screen bg-purple-100 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-40" src={igpLogo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-bold text-purple-800">Sign in to your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="text-red-500 italic text-xs text-right">{errorMessage}</div>
          <form onSubmit={e => handleSubmit(e)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={loginCredentials.email}
                  onChange={e => {
                    e.persist();
                    setLoginCredentials(prev => ({
                      ...prev,
                      email: e.target.value
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div className="mt-6">
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={loginCredentials.password}
                  onChange={e => {
                    e.persist();
                    setLoginCredentials(prev => ({
                      ...prev,
                      password: e.target.value
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div className="mt-6 flex items-center justify-between">
              <div className="flex items-center">
                <div className="w-24">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-green-600 transition duration-150 ease-in-out"
                  >
                    Login
                  </button>
                </div>
              </div>

              <div className="text-sm leading-5">
                <a
                  href="/"
                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm"></span>
            </div>
          </form>

          <div className="mt-6">
            <hr />

            <div className="mt-6 ">
              <div className="text-purple-800 font-bold">Don't have an account?</div>
              <div className="mt-3">
                <a
                  href="/"
                  className="font-medium text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Register Here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
