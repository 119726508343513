import LogRocket from "logrocket";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/main.css";
import App from "./App";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_API_URL &&
  process.env.REACT_APP_API_URL === "https://api.impactgenome.com"
) {
  LogRocket.init("impact-genome/hub");
}
if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(<App />, document.getElementById("root"));
