import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import styles from "../../styles";
import checkmark from "../../images/checkmark.png";

const Activities = (props) => {
  const { object, empty } = props;
  const answer = object.responsevalue;
  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Below you will find a list of common program components associated with your area of work. We provide this list so that you can give a more standardized, detailed explanation of your program's design. These core components are evidence-based and are designed to be universal and comprehensive. Note: we don't necessarily endorse these components.Using a 1 - 5 scale, please score how important each core component is to the success of your program."}
      </Text>
      <View style={{ flexDirection: "row", flexWrap: true }}>
        <View style={{ width: "50%" }}>
          <Text>Name</Text>
        </View>
        <View style={{ width: "8.33%" }}>
          <Text style={{ textAlign: "center" }}>N/A</Text>
        </View>
        <View style={{ width: "8.33%" }}>
          <Text style={{ textAlign: "center" }}>1</Text>
        </View>
        <View style={{ width: "8.33%" }}>
          <Text style={{ textAlign: "center" }}>2</Text>
        </View>
        <View style={{ width: "8.33%" }}>
          <Text style={{ textAlign: "center" }}>3</Text>
        </View>
        <View style={{ width: "8.33%" }}>
          <Text style={{ textAlign: "center" }}>4</Text>
        </View>
        <View style={{ width: "8.33%" }}>
          <Text style={{ textAlign: "center" }}>5</Text>
        </View>
      </View>
      {empty ? (
        <Text></Text>
      ) : answer ? (
        answer.map((gene) => {
          return gene ? (
            <View style={{ flexDirection: "row", flexWrap: true }}>
              <View style={{ width: "50%" }}>
                <Text>{gene.name}</Text>
              </View>
              <View style={{ width: "8.33%" }}>
                <Text style={{ textAlign: "center", color: "green" }}>
                  {gene.value ? "" : <Image src={checkmark}></Image>}
                </Text>
              </View>
              <View style={{ width: "8.33%" }}>
                <Text style={{ textAlign: "center", color: "green" }}>
                  {gene.value === "1" ? <Image src={checkmark}></Image> : ""}
                </Text>
              </View>
              <View style={{ width: "8.33%" }}>
                <Text style={{ textAlign: "center" }}>
                  {gene.value === "2" ? <Image src={checkmark}></Image> : ""}
                </Text>
              </View>
              <View style={{ width: "8.33%" }}>
                <Text style={{ textAlign: "center", color: "green" }}>
                  {gene.value === "3" ? <Image src={checkmark}></Image> : ""}
                </Text>
              </View>
              <View style={{ width: "8.33%" }}>
                <Text style={{ textAlign: "center", color: "green" }}>
                  {gene.value === "4" ? <Image src={checkmark}></Image> : ""}
                </Text>
              </View>
              <View style={{ width: "8.33%" }}>
                <Text style={{ textAlign: "center", color: "green" }}>
                  {gene.value === "5" ? <Image src={checkmark}></Image> : ""}
                </Text>
              </View>
            </View>
          ) : (
            <Text>Not Found</Text>
          );
        })
      ) : (
        <Text></Text>
      )}
    </>
  );
};

export default Activities;
