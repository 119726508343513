import React from "react";
import { saveAs } from "file-saver";
import { ResponseContextProvider } from "./context/ResponseContext";
import { GlobalContextProvider } from "./context/GlobalContext";
import {
  Page,
  Document,
  PDFDownloadLink,
  BlobProvider,
  pdf,
} from "@react-pdf/renderer";
import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import SurveyPrinter from "./components/SurveyPrinter";
import repsonsedata from "./sampleresponse.json";
import globaldata from "./sampleglobal.json";
import LoadingIcon from "../../elements/LoadingIcon";
import styles from "./styles";

import "./App.scss";

export const ViewResponses = ({ responseData, globalData, empty }) => {
  return (
    <div className="pdf--container">
      <PDFViewer style={{ width: "100%", minHeight: "70vh" }}>
        <ResponseContextProvider value={[responseData, () => {}]}>
          <GlobalContextProvider value={[globalData, () => {}]}>
            <Document>
              <Page size="A4" style={styles.page} ruler={false}>
                <SurveyPrinter
                  data={responseData}
                  globaldata={globalData}
                  empty={empty}
                />
              </Page>
            </Document>
          </GlobalContextProvider>
        </ResponseContextProvider>
      </PDFViewer>
    </div>
  );
};

export const DownloadResponses = ({
  responseData,
  globalData,
  empty,
  children,
}) => {
  return (
    <div className="pdf--container">
      <PDFDownloadLink
        document={
          <Document>
            <Page size="A4" style={styles.page}>
              <ResponseContextProvider value={[responseData, () => {}]}>
                <SurveyPrinter
                  data={responseData}
                  globaldata={globalData}
                  empty={empty}
                />
              </ResponseContextProvider>
            </Page>
          </Document>
        }
        fileName="report.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? <LoadingIcon size="20px" /> : children
        }
      </PDFDownloadLink>
    </div>
  );
};
export const generatePdf = async ({
  responseData,
  globalData,
  empty,
  children,
}) => {
  if (responseData && globalData) {
    const blob = await pdf(
      <Document>
        <Page size="A4" style={styles.page}>
          <ResponseContextProvider value={[responseData, () => {}]}>
            <SurveyPrinter
              data={responseData}
              globaldata={globalData}
              empty={empty}
            />
          </ResponseContextProvider>
        </Page>
      </Document>
    ).toBlob();

    console.log(blob);
    saveAs(blob, "report");
  }
};
