import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintBrush,
  faGavel,
  faUsers,
  faFlag,
  faDatabase,
  faBook,
  faGlobe,
  faHeartbeat,
  faFlask,
  faTree,
  faMapSigns,
  faChild,
} from "@fortawesome/free-solid-svg-icons";

function ImpactAreaIcon({ impactArea, className, size = "sm" }) {
  const JSX = ({ icon, className, size }) => (
    <div className={className}>
      <FontAwesomeIcon size={size} icon={icon} />
    </div>
  );
  switch (impactArea.toLowerCase()) {
    case "arts": {
      return <JSX className={className} icon={faPaintBrush} size={size} />;
    }
    case "criminal justice": {
      return <JSX className={className} icon={faGavel} size={size} />;
    }
    case "critical human needs": {
      return <JSX className={className} icon={faUsers} size={size} />;
    }
    case "culture & identity": {
      return <JSX className={className} icon={faFlag} size={size} />;
    }
    case "economic development": {
      return <JSX className={className} icon={faDatabase} size={size} />;
    }
    case "education": {
      return <JSX className={className} icon={faBook} size={size} />;
    }
    case "global development": {
      return <JSX className={className} icon={faGlobe} size={size} />;
    }
    case "public health": {
      return <JSX className={className} icon={faHeartbeat} size={size} />;
    }
    case "science & technology": {
      return <JSX className={className} icon={faFlask} size={size} />;
    }
    case "sustainability and environment": {
      return <JSX className={className} icon={faTree} size={size} />;
    }
    case "systems change": {
      return <JSX className={className} icon={faMapSigns} size={size} />;
    }
    case "youth": {
      return <JSX className={className} icon={faChild} size={size} />;
    }
  }
}

export default ImpactAreaIcon;
