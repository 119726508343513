import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../../styles";
import globalHelper from "../../helpers/globalVars";

const PrimaryReach = (props) => {
  const { globaldata, object, empty } = props;
  const primaryoutcome = globalHelper.getValueByFieldName(
    "primaryoutcome",
    globaldata
  );
  const answer = object.responsevalue;

  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Please complete the following impact statement by inserting your reach and outcomes."}
      </Text>
      {empty ? (
        <Text></Text>
      ) : answer ? (
        answer.map((input) => {
          return (
            <Text>
              {`Out of ${
                input.inputValues.outofnumber && input.inputValues.outofnumber
              } ${
                input.inputValues.beneficiary && input.inputValues.beneficiary
              }, ${
                input.inputValues.achievednumber &&
                input.inputValues.achievednumber
              } achieved the outcome of ${primaryoutcome}.`}
            </Text>
          );
        })
      ) : (
        <Text></Text>
      )}
    </>
  );
};

export default PrimaryReach;
