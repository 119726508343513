import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../styles";

const TopGenes = (props) => {
  const { object, empty } = props;

  const sortAnswer = (array) =>
    array.sort((a, b) => {
      if (a.rank < b.rank) {
        return 1;
      } else {
        return -1;
      }
    });
  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Below are the core components that you indicated were part of your program's design. Which 5 components would you say are Most critical to your program’s design?"}
      </Text>
      {empty ? (
        <Text></Text>
      ) : object.responsevalue ? (
        sortAnswer(object.responsevalue).map((option, i) => {
          return option ? (
            <View style={{ flexDirection: "column" }}>
              <Text>{i + 1 + ". " + option.name}</Text>
            </View>
          ) : (
            <Text>'Not Found'</Text>
          );
        })
      ) : (
        <Text></Text>
      )}
    </>
  );
};

export default TopGenes;
