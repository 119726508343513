import React from "react";
import { Link, useHistory, Prompt } from "react-router-dom";
import { Transition } from "@headlessui/react";
import useApiRequest from "../hooks/useApiRequest";
import useOutsideClick from "../hooks/useOutsideClick";
import useUnload from "../hooks/useUnload";
import { GlobalNav, Header, Main } from "../templates/Layout";
import { Breadcrumb } from "../elements/Breadcrumb";
import FullProgramBreadcrumb from "./FullProgramBreadcrumb";
import SectionSelect from "./SectionSelect";

import ImpactAreaIcon from "../components/ImpactAreaIcon";
import Button from "../elements/Button";
import { Sidebar } from "../templates/Layout";
import LoadingIcon from "../elements/LoadingIcon";
import Search from "../elements/Search";

function OutcomeElements({ selectedOutcomes, setSelectedOutcomes, outcomes }) {
  return (
    <div className="flex flex-wrap w-full mb-6">
      {outcomes.map((outcome, index) => {
        const selected = selectedOutcomes.some(
          (selectedOutcome) => outcome.outcomeid === selectedOutcome?.outcomeid
        );
        return (
          <React.Fragment key={index}>
            <div
              className={`${
                selected
                  ? "border-2 border-blue-500 bg-blue-500 bg-opacity-5"
                  : "border"
              } shadow-sm rounded md:w-56 lg:w-72 mr-4 mt-2 mb-2 p-4 cursor-pointer relative`}
              onClick={() => setSelectedOutcomes(outcome)}
            >
              {selected && (
                <div className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 rounded-full bg-blue-500 flex justify-center items-center">
                  <svg
                    className="inline-block"
                    width="11"
                    height="9"
                    fill="#4D93B8"
                    viewBox="0 0 11 9"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2271 2.17576L10.3257 2.11685L9.16378 0.954468L4.10145 5.99704L1.83607 3.73166L0.674194 4.87424L4.10138 8.30143L10.2271 2.17576Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}

              <div className="font-semibold">{outcome.name}</div>
              <div className="text-xs mb-8">{outcome.description}</div>
              <div className="absolute bottom-0 right-0 text-tiny mt-4 p-4 text-gray-500">{`Outcome ID: ${outcome.outcomecode}`}</div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

function ImpactAreaCard({
  impactArea,
  selected,
  setSelectedImpactArea,
  selectedOutcomes,
}) {
  const includedSelectedOutcomes = () => {
    // if(!selectedOutcomes.length){
    //     return 0
    // }else{
    return selectedOutcomes.reduce((acc, cur) => {
      if (impactArea.impactareaid === cur.impactareaid) {
        return acc + 1;
      } else return acc;
    }, 0);
    // }
  };
  return (
    <div
      className={`${
        selected
          ? "bg-white border-l-4 border-b-0 border-blue-500 -mr-1 text-blue-500"
          : "border-b border-gray-400"
      }  p-6 cursor-pointer`}
      onClick={setSelectedImpactArea}
    >
      <div className="flex flex-no-wrap justify-content-start items-start">
        <ImpactAreaIcon impactArea={impactArea.name} size="lg" />
        <div className="ml-4">
          <div className="font-bold text-lg">{impactArea.name}</div>
          <div>{impactArea.description}</div>
        </div>
        {!!includedSelectedOutcomes() && (
          <div className="rounded-full w-10  bg-blue-100 text-xs text-center flex-none">
            {includedSelectedOutcomes()}
          </div>
        )}
      </div>
    </div>
  );
}

function SelectedImpactAreas({
  impactAreas,
  selectedImpactArea,
  setSelectedImpactArea,
  selectedOutcomes,
}) {
  //REMOVE FOR PRODUCTION

  return (
    <div>
      <div className="uppercase tracking-wider font-bold border-b text-gray-600 border-gray-400 text-xs px-6 pt-6 pb-4">
        Selected Impact Areas
      </div>
      {impactAreas &&
        impactAreas.map((impactArea, index) => (
          <React.Fragment key={index}>
            <ImpactAreaCard
              impactArea={impactArea}
              setSelectedImpactArea={() => setSelectedImpactArea(impactArea)}
              selected={
                impactArea.impactareaid === selectedImpactArea?.impactareaid
              }
              selectedOutcomes={selectedOutcomes}
            />
          </React.Fragment>
        ))}
    </div>
  );
}

function AdditionalImpactAreas({
  impactAreas,
  selectedImpactArea,
  setSelectedImpactArea,
  selectedOutcomes,
}) {
  return (
    <div>
      <div className="uppercase tracking-wider font-bold border-b text-gray-600 border-gray-400 text-xs px-6 pt-10 pb-4">
        Additional Impact Areas
      </div>
      {impactAreas.map((impactArea, index) => (
        <React.Fragment key={index}>
          <ImpactAreaCard
            impactArea={impactArea}
            setSelectedImpactArea={() => setSelectedImpactArea(impactArea)}
            selected={
              impactArea.impactareaid === selectedImpactArea?.impactareaid
            }
            selectedOutcomes={selectedOutcomes}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

function SelectOutcomesToolbar({
  selectedOutcomes,
  setSelectedOutcomes,
  outcomeSearch,
  setOutcomeSearch,
}) {
  const [showOutcomes, setShowOutcomes] = React.useState(false);
  const ref = React.useRef();
  useOutsideClick(ref, () => setShowOutcomes(false));

  return (
    <div className="  h-14 -mr-6 flex flex-no-wrap justify-end items-center relative">
      <div className="h-full border-b w-full flex justify-end items-center -mr-2">
        <div className="w-56 mr-2">
          <Search
            value={outcomeSearch}
            handleChange={(e) => setOutcomeSearch(e)}
            placeholder="Search for outcomes"
          />
        </div>
      </div>
      <div
        className={`h-full px-4 border-l border-b flex items-center ml-2 cursor-pointer  ${
          showOutcomes ? "border-b-white" : "border-b-grey-400"
        }`}
        onClick={() => setShowOutcomes(!showOutcomes)}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H5C5.53043 1 6.03914 1.21071 6.41421 1.58579C6.78929 1.96086 7 2.46957 7 3V5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7H3C2.46957 7 1.96086 6.78929 1.58579 6.41421C1.21071 6.03914 1 5.53043 1 5V3ZM11 3C11 2.46957 11.2107 1.96086 11.5858 1.58579C11.9609 1.21071 12.4696 1 13 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V5C17 5.53043 16.7893 6.03914 16.4142 6.41421C16.0391 6.78929 15.5304 7 15 7H13C12.4696 7 11.9609 6.78929 11.5858 6.41421C11.2107 6.03914 11 5.53043 11 5V3ZM1 13C1 12.4696 1.21071 11.9609 1.58579 11.5858C1.96086 11.2107 2.46957 11 3 11H5C5.53043 11 6.03914 11.2107 6.41421 11.5858C6.78929 11.9609 7 12.4696 7 13V15C7 15.5304 6.78929 16.0391 6.41421 16.4142C6.03914 16.7893 5.53043 17 5 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V13ZM11 13C11 12.4696 11.2107 11.9609 11.5858 11.5858C11.9609 11.2107 12.4696 11 13 11H15C15.5304 11 16.0391 11.2107 16.4142 11.5858C16.7893 11.9609 17 12.4696 17 13V15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17H13C12.4696 17 11.9609 16.7893 11.5858 16.4142C11.2107 16.0391 11 15.5304 11 15V13Z"
            stroke="#4E4E74"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="text-lg font-bold mx-2">{selectedOutcomes.length}</div>
        {!showOutcomes ? (
          <svg style={{ width: "16px", height: "16px" }} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
            />
          </svg>
        ) : (
          <svg style={{ width: "16px", height: "16px" }} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
            />
          </svg>
        )}
      </div>
      <Transition
        show={showOutcomes}
        enter="transition-opacity ease-linear duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute top-0 right-0 w-84 overflow-auto border-l border-b rounded-b shadow-sm bg-white mt-14 z-10"
          style={{ maxHeight: "400px" }}
          ref={ref}
        >
          {selectedOutcomes.map((outcome, index) => {
            return (
              <div className="border-b">
                <div
                  className="relative px-4 h-auto"
                  key={index}
                  style={{ minHeight: "10rem" }}
                >
                  <div
                    className="absolute top-0 right-0 mr-4 flex flex-no-wrap items-center cursor-pointer text-red-600 hover:text-red-700"
                    onClick={() => setSelectedOutcomes(outcome)}
                  >
                    <div
                      className=" uppercase mr-1"
                      style={{ fontSize: "8px" }}
                    >
                      Remove
                    </div>
                    <div className="uppercase text-tiny font-semibold">X</div>
                  </div>
                  <div className="text-tiny uppercase tracking-wider text-gray-500 mt-6 ">
                    {`${outcome.impactareaname} > ${outcome.genomename}`}
                  </div>
                  <div className="font-semibold">{outcome.name}</div>
                  <div className="text-xs pb-6">{outcome.description}</div>
                  <div className="absolute right-0 bottom-0 text-tiny -p-4 mb-2 mr-4  text-right text-gray-500">{`Outcome ID: ${outcome.outcomecode}`}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

function SelectOutcomes(props) {
  const {
    cookieData,
    selectedImpactAreas,
    selectedOutcomes,
    setSelectedOutcomes,
    sections,
    handleSectionEnable,
    handleCancel,
  } = props;
  const [selectedImpactArea, setSelectedImpactArea] = React.useState(null);
  const [outcomeSearch, setOutcomeSearch] = React.useState("");
  const [sidebarHeight, setSidebarHeight] = React.useState(0);
  const outcomeRef = React.useRef(null);
  const ref = React.useRef(null);
  const history = useHistory();

  const { state: outcomes } = useApiRequest({
    reqType: "getOutcomesFull",
  });
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  React.useEffect(() => {
    (() => {
      if (sections[3].disabled) {
        handleSectionEnable(3);
      }
    })();
  }, []);
  React.useEffect(() => {
    (() =>
      outcomeRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" }))();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [selectedImpactArea]);
  React.useEffect(() => {
    (() => setSidebarHeight(ref.current?.clientHeight))();
  }, [outcomes]);
  const selectedImpactAreasFull =
    outcomes.data &&
    outcomes.data.filter((outcome) =>
      selectedImpactAreas.some(
        (selectedImpactArea) =>
          selectedImpactArea.impactareaid === outcome.impactareaid
      )
    );
  const unselectedImpactAreas =
    outcomes.data &&
    outcomes.data.filter(
      (outcome) =>
        !selectedImpactAreas.some(
          (selectedImpactArea) =>
            selectedImpactArea.impactareaid === outcome.impactareaid
        )
    );
  const currentlyViewedOutcomes = (outcomes) => {
    const filterOutcomesByText = () => {
      const searchString = outcomeSearch.toLowerCase();
      return outcomes.filter((_outcome) => {
        for (const genome of _outcome.genomes) {
          for (const outcome of genome.outcomes) {
            return (
              outcome.impactareaname.toLowerCase().includes(searchString) ||
              outcome.genomename.toLowerCase().includes(searchString) ||
              outcome.description.toLowerCase().includes(searchString) ||
              outcome.name.toLowerCase().includes(searchString)
            );
          }
        }
      });
    };
    const test = outcomeSearch.length
      ? filterOutcomesByText()
      : selectedImpactArea
      ? outcomes.filter(
          (outcome) => outcome.impactareaid === selectedImpactArea.impactareaid
        )
      : outcomes.filter((outcome) =>
          selectedImpactAreas.some(
            (element) => element.impactareaid === outcome.impactareaid
          )
        );
    return test;
  };
  return (
    <>
      <Prompt
        message={(params) => {
          return params.pathname.includes("createprogram")
            ? true
            : "You may have unsaved data. Are you sure you want to leave?";
        }}
      />
      <div className="bg-purple-800 pb-14">
        <GlobalNav cookieData={cookieData} />
        <Header>
          <div className="flex flex-nowrap justify-between w-full items-start">
            <div>
              <div>
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                  {props.isEdit ? "Edit Program" : "Add New Program"}
                </h2>

                <div className="text-white">
                  Your inventory of social programs or projects.
                </div>
              </div>
            </div>
            <SectionSelect sections={props.sections} currentSection={3} />
          </div>
        </Header>
      </div>
      <Main>
        <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
          <div className="">Select Outcomes</div>
          <div className="">Step 4 of 7</div>
        </div>
        <hr className="-mx-6" />
        <div className="flex">
          {outcomes.data ? (
            <>
              <Sidebar className="-mb-6">
                <div ref={ref} className="-mx-4">
                  <SelectedImpactAreas
                    impactAreas={selectedImpactAreasFull}
                    selectedImpactArea={selectedImpactArea}
                    setSelectedImpactArea={(impactArea) => {
                      setSelectedImpactArea(impactArea);
                      setOutcomeSearch("");
                    }}
                    selectedOutcomes={selectedOutcomes}
                  />
                  <AdditionalImpactAreas
                    impactAreas={unselectedImpactAreas}
                    selectedImpactArea={selectedImpactArea}
                    setSelectedImpactArea={(impactArea) => {
                      setSelectedImpactArea(impactArea);
                      setOutcomeSearch("");
                    }}
                    selectedOutcomes={selectedOutcomes}
                  />
                </div>
              </Sidebar>
              <div className="-mb-6 w-full">
                <SelectOutcomesToolbar
                  selectedOutcomes={selectedOutcomes}
                  setSelectedOutcomes={setSelectedOutcomes}
                  outcomeSearch={outcomeSearch}
                  setOutcomeSearch={setOutcomeSearch}
                />

                <div className="mx-4">
                  <div
                    className="overflow-auto w-full pl-10 pr-6 my-10 bg-white"
                    style={{
                      height: `${sidebarHeight}px`,
                    }}
                    ref={outcomeRef}
                  >
                    <div className="mb-6">
                      {currentlyViewedOutcomes(outcomes.data).map(
                        (impactArea, index) => (
                          <React.Fragment key={index}>
                            <div className="flex flex-no-wrap items-center mb-6">
                              <ImpactAreaIcon
                                size="2x"
                                className="text-blue-500"
                                impactArea={impactArea.name}
                              />
                              <div className="text-blue-500 text-3xl font-bold ml-3">
                                {impactArea.name}
                              </div>
                            </div>
                            {impactArea.genomes.map((genome, index) => (
                              <React.Fragment key={index}>
                                <div className="flex flex-no-wrap w-full mb-4">
                                  <div className="uppercase tracking-widest text-sm flex-none -mb-1 mr-1">
                                    {genome.name}
                                  </div>
                                  <div className="border-b-2 w-full" />
                                </div>
                                <OutcomeElements
                                  selectedOutcomes={selectedOutcomes}
                                  setSelectedOutcomes={setSelectedOutcomes}
                                  outcomes={genome.outcomes}
                                />
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                  <hr className="-mx-10 border" />
                  <div className="flex justify-between my-6">
                    <Button
                      color="gray"
                      className="justify-self-start text-right w-48"
                      onClick={() => handleCancel()}
                    >{`Cancel`}</Button>
                    <div className="flex justify-end">
                      <Link to="/createprogram/impactareas">
                        <Button
                          color="blue"
                          className="text-right w-48 mr-8"
                        >{`Previous`}</Button>
                      </Link>

                      <Button
                        color="green"
                        className="text-right w-48"
                        onClick={() =>
                          history.push("/createprogram/selectprimaryoutcome")
                        }
                        disabled={!selectedOutcomes.length}
                      >{`Save & Continue`}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <LoadingIcon />
          )}
        </div>
      </Main>
    </>
  );
}

export default SelectOutcomes;
