import React from "react";
import { Transition } from "../helpers/Transition";

export function ModalBody({ title, children, alert = false, noIcon = false }) {
  return (
    <div>
      <div className="">
        {alert ? (
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <svg
              className="h-6 w-6 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
        ) : noIcon ? null : (
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg
              className="h-6 w-6 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h3
          className="text-lg leading-6 font-medium text-gray-900 capitalize"
          id="modal-headline"
        >
          {title}
        </h3>
        <div className="mt-2">
          <p className="text-sm leading-5 text-gray-500">{children}</p>
        </div>
      </div>
    </div>
  );
}
export function ModalButton({
  onClick = () => {},
  text,
  disabled = false,
  color = "gray",
  className = "",
}) {
  return (
    <div className="mt-5 sm:mt-6">
      <span className="flex  w-full rounded-md shadow-sm">
        {disabled ? (
          <button
            type="button"
            className={`opacity-50 cursor-not-allowed inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-${color}-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${className}`}
          >
            {text}
          </button>
        ) : (
          <button
            type="button"
            onClick={() => onClick()}
            className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-${color}-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${className}`}
          >
            {text}
          </button>
        )}
      </span>
    </div>
  );
}

export function ModalParent({ isOpen, children, maxWidth = "max-w-sm" }) {
  //remove in production
  return (
    <>
      <Transition
        show={isOpen}
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/*
          Modal panel, show/hide based on modal state.
      
          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        */}
          <Transition
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:${maxWidth} sm:w-full sm:p-6`}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {children}
            </div>
          </Transition>
        </div>
      </Transition>
    </>
  );
}
