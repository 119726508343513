import { useLocation } from "react-router-dom";
import queryString from "query-string";

function useQuery() {
  const parsedQuery = queryString.parse(useLocation().search);
  const unparsedQuery = useLocation().search.substring(1);
  return { parsedQuery, unparsedQuery };
}

export default useQuery;
