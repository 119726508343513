import React from "react";
import { Link, useHistory, useRouteMatch, Prompt } from "react-router-dom";
import useApiRequest from "../hooks/useApiRequest";
import useUnload from "../hooks/useUnload";
import { GlobalNav, Header, Main } from "../templates/Layout";
import { Breadcrumb } from "../elements/Breadcrumb";
import FullProgramBreadcrumb from "./FullProgramBreadcrumb";
import SectionSelect from "./SectionSelect";
import Input from "../elements/Input";
import Textarea from "../elements/Textarea";
import Dropdown from "../elements/Dropdown";
import Button from "../elements/Button";
import { Sidebar } from "../templates/Layout";

function Overview(props) {
  const {
    cookieData,
    newProgramState,
    setNewProgramState,
    handleCancel,
  } = props;
  const { state: countryOptions } = useApiRequest({ reqType: "getLocation" });

  let history = useHistory();
  let { path, url } = useRouteMatch();
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  // React.useEffect(() => {
  //   const test = history;
  // }, []);

  return (
    <>
      <Prompt
        message={(params) => {
          return params.pathname.includes("createprogram")
            ? true
            : "You may have unsaved data. Are you sure you want to leave?";
        }}
      />
      <div className="bg-purple-800 pb-14">
        <GlobalNav cookieData={cookieData} />
        <Header>
          <div className="flex flex-nowrap justify-between w-full items-start">
            <div>
              <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                {props.isEdit ? "Edit Program" : "Add New Program"}
              </h2>

              <div className="text-white">
                Your inventory of social programs or projects.
              </div>
            </div>
            <SectionSelect sections={props.sections} currentSection={0} />
          </div>
        </Header>
      </div>
      <Main>
        <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
          <div className="">Overview</div>
          <div className="">Step 1 of 7</div>
        </div>
        <hr className="-mx-6" />
        <div className="flex">
          <Sidebar className="-mb-6">
            <div className="-mx-6"></div>
            <div className="flex flex-no-wrap justify-start items-center">
              <div className="mr-3 mt-2">
                {newProgramState.programname.length ? (
                  <svg
                    className="w-3 h-3 fill-current text-green-500"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    />
                  </svg>
                ) : (
                  <svg className="w-3 h-3 fill-current" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    />
                  </svg>
                )}
              </div>
              <div className="mt-2 font-semibold">Program Name</div>
            </div>
            <div className="flex flex-no-wrap justify-start items-center mt-2">
              <div className="mr-3 mt-2">
                {newProgramState.programoverview.length ? (
                  <svg
                    className="w-3 h-3 fill-current text-green-500"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    />
                  </svg>
                ) : (
                  <svg className="w-3 h-3 fill-current" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    />
                  </svg>
                )}
              </div>
              <div className="mt-2 font-semibold">Program Description</div>
            </div>
            <div className="flex flex-no-wrap justify-start items-center mt-2">
              <div className="mr-3 mt-2">
                {newProgramState.location.length ? (
                  <svg
                    className="w-3 h-3 fill-current text-green-500"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    />
                  </svg>
                ) : (
                  <svg className="w-3 h-3 fill-current" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    />
                  </svg>
                )}
              </div>
              <div className="mt-2 font-semibold">Operating Locations</div>
            </div>
            <div className="flex flex-no-wrap justify-start items-center mt-2">
              <div className="mr-3 mt-2">
                <svg
                  className="w-3 h-3 fill-current text-green-500"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                  />
                </svg>
              </div>
              <div className="mt-2 font-semibold">Promote to Donors</div>
            </div>
          </Sidebar>
          <div className=" bg-purple-100 -mx-6 -mb-6">
            <div className="w-full mx-auto  px-6 pb-6   bg-white">
              <div className="max-w-2xl font-bold mb-1 pt-6">Program Name</div>
              <div className="max-w-2xl">
                <p className="mb-4">
                  Enter the name of a discrete social program (sometimes
                  referred to as a project, intervention, etc.). A program
                  typically targets a specific beneficiary group and aims to
                  achieve one or more discrete social outcomes. If your
                  organization has multiple programs, please choose one (you may
                  register additional programs separately).
                </p>
                <p>
                  For example, your organization may run an after-school science
                  lab (STEM X-Labs) aimed at increasing STEM interest and also a
                  summer camp (STEM Summers) intended to increase STEM
                  proficiency. These would be two separate programs.
                </p>
              </div>
              <Input
                className="max-w-2xl mt-4 mb-6"
                value={newProgramState.programname}
                handleChange={(e) => setNewProgramState({ programname: e })}
                placeholder=""
              />
              <hr className=" border" />
              <div className=" font-bold mb-1 mt-6">
                Brief Program Description
              </div>
              <div className="max-w-2xl">
                <ul className=" list-disc mb-4">
                  <li className="ml-5">
                    Provide a 3-4 sentence overview of the program
                  </li>
                  <li className="ml-5">
                    Make note of key objectives and activities
                  </li>{" "}
                  <li className="ml-5">Please write in third-person</li>
                  <li className="ml-5">
                    Description may be seen by donors and others who want to
                    learn about your program
                  </li>
                </ul>
                <p>Sample response:</p>
                <p>
                  The Science and Technology Institute hosts a Robotics
                  Competition for high school students to increase STEM
                  interest. Prior to the competition, there is a 5-week season
                  where teams of students work with a STEM mentor to design and
                  build robots for the competition. During the competition,
                  students engage their robots in a difficult field game against
                  like-minded competitors.
                </p>
              </div>
              <Textarea
                className="max-w-2xl mt-4 mb-6"
                value={newProgramState.programoverview}
                handleChange={(e) => setNewProgramState({ programoverview: e })}
              />
              <hr className=" border" />
              <div className=" font-bold mb-1 mt-6">Operating Location(s)</div>
              <div className="max-w-2xl">
                <p>
                  This is typically where your beneficiaries are located. If you
                  are unsure how to answer, please contact us at{" "}
                  <a
                    href="mailto:help@impactgenome.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help@impactgenome.org
                  </a>{" "}
                  for assistance.
                </p>
              </div>
              <div className="max-w-2xl mt-4 mb-6">
                <Dropdown
                  isMulti
                  placeholder="Please select all countries that apply"
                  options={
                    countryOptions.data &&
                    countryOptions.data.map((country) => ({
                      label: country.name,
                      value: country.countryid,
                    }))
                  }
                  value={
                    newProgramState.location.length
                      ? newProgramState.location.map((location) => ({
                          label: location.name,
                          value: location.countryid,
                        }))
                      : null
                  }
                  handleChange={(e) =>
                    setNewProgramState({
                      location: e.map((location) => ({
                        name: location.label,
                        countryid: location.value,
                      })),
                    })
                  }
                />
              </div>
              <hr className=" border" />
              <div className="max-w-2xl flex flex-no-wrap">
                <div
                  className={`flex-none mr-6 mt-6 h-6 w-6 border cursor-pointer ${
                    newProgramState.ispublic
                      ? "border-green-500 bg-green-500"
                      : "border-gray-800 bg-white"
                  } rounded`}
                  onClick={() =>
                    setNewProgramState({ ispublic: !newProgramState.ispublic })
                  }
                />
                <div>
                  <div className=" font-bold mb-1 mt-6">Promote to donors</div>
                  <div className="mb-8">
                    <p>
                      Checking this box will enable the Impact Genome Project®
                      to promote this program and key data points to donors who
                      value its outcomes and want to contribute. This includes
                      our partnership with Candid, where an IGP badge will
                      appear on your organization’s GuideStar page, and
                      inclusion in the Impact Genome Project’s Nonprofit Impact
                      Registry <em>(coming soon)</em>, a nonprofit discovery
                      tool for donors.
                    </p>
                  </div>
                </div>
              </div>

              <hr className="border mt-10" />
              <div className="flex justify-between my-6">
                <Button
                  color="gray"
                  className="text-right w-48"
                  onClick={() => handleCancel()}
                >{`Cancel`}</Button>
                <Button
                  color="green"
                  className="text-right w-48"
                  onClick={() => history.push("/createprogram/classification")}
                  disabled={
                    !newProgramState.programname ||
                    !newProgramState.programoverview ||
                    !newProgramState.location.length
                  }
                >{`Save & Continue`}</Button>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
}

export default Overview;
