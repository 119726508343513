import React from 'react';
import { Text } from '@react-pdf/renderer';

import styles from '../../styles';

const Dropdown = (props) => {
  const { object, empty } = props;
  const answer = object.responsevalue;

  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber + '. ' + object.questionbody}
      </Text>
      <Text>{empty ? '' : answer ? answer.text : ''}</Text>
    </>
  );
};

export default Dropdown;
