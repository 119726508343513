import React from "react";
import { Transition } from "@headlessui/react";

function Component({
  value = "",
  handleChange = () => {},
  options = [],
  label = "Label",
  show = false,
  handleShow = () => {},
  isDisabled = () => {},
  placeholder = "Please select an option",
}) {
  const [isOpen, setIsOpen] = React.useState(show);
  React.useEffect(() => {
    (() => handleShow(isOpen))();
  }, [isOpen]);
  React.useEffect(() => {
    (() => isOpen !== show && setIsOpen(show))();
  }, [show]);
  return (
    <div className="space-y-1">
      <label
        id="listbox-label"
        className="block text-sm leading-5 font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative">
        <span
          className="inline-block w-full rounded-md shadow-sm"
          onClick={() => setIsOpen(!isOpen)}
        >
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            <span className={`block truncate ${!value && "text-gray-400"}`}>
              {value ? value : placeholder}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <svg
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </span>

        <Transition
          show={isOpen}
          enter=""
          enterFrom=""
          enterTo=""
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10">
            <ul
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              {/*
                Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
      
                Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
              */}
              {options.map((option, index) => {
                const selected = option.name === value;
                const disabled = isDisabled(option);
                return (
                  <React.Fragment key={index}>
                    <li
                      id="listbox-option-0"
                      role="option"
                      className={`
                      ${
                        disabled
                          ? " bg-disabled text-gray-400 cursor-not-allowed"
                          : " text-gray-900 hover:text-white hover:bg-indigo-600 cursor-default"
                      }
                      select-none relative py-2 pl-3 pr-9`}
                      onClick={() => !disabled && handleChange(option)}
                    >
                      {/* Selected: "font-semibold", Not Selected: "font-normal" */}
                      <span
                        className={`${
                          selected ? "font-semibold" : "font-normal"
                        } block truncate`}
                      >
                        {option.name}
                      </span>

                      {/*
                  Checkmark, only display for selected option.
      
                  Highlighted: "text-white", Not Highlighted: "text-indigo-600"
                */}
                      {selected && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                          {/* Heroicon name: check */}
                          <svg
                            className="h-5 w-5 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            // fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default Component;
