import React from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import useOutsideClick from "../hooks/useOutsideClick";

function SectionSelect({ sections, currentSection }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef();
  useOutsideClick(ref, () => setIsOpen(false));
  return (
    <div className="ml-3 relative" ref={ref}>
      <div>
        <button
          className="flex text-sm border-2 border-transparent rounded-full focus:outline-none  transition duration-150 ease-in-out"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
          onClick={() => setIsOpen((prev) => setIsOpen(!prev))}
        >
          <div className="text-white flex flex-no-wrap items-center">
            <div className="text-lg mr-2 font-semibold">Sections:</div>
            <div className="pr-1">{sections[currentSection].text}</div>
            <div>
              {isOpen ? (
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor" d="M7,15L12,10L17,15H7Z" />
                </svg>
              ) : (
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                </svg>
              )}
            </div>
          </div>
        </button>
      </div>
      {/*
                  Profile dropdown panel, show/hide based on dropdown state.
      
                  For animated transitions, wrap the next element in this <Transition> component from https://gist.github.com/adamwathan/3b9f3ad1a285a2d1b482769aeb862467:
                */}

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10">
          <div
            className=" rounded-md bg-white shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {sections &&
              sections.map((item, index) => (
                <React.Fragment key={index}>
                  {item.disabled ? (
                    <div className="block px-4 py-2 text-sm leading-5 bg-disabled text-gray-400 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                      {item.text}
                    </div>
                  ) : (
                    <Link
                      to={item.link}
                      className=" block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 cursor-pointer focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                    >
                      {item.text}
                    </Link>
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default SectionSelect;
