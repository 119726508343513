import React from "react";
import { View, Text } from "@react-pdf/renderer";

import styles from "../../styles";

const TargetedOutcomes = (props) => {
  const { object, empty } = props;
  const answer = object.responsevalue;

  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Please select any additional social outcome(s) this program is designed to produce. (select all that apply)."}
      </Text>
      {empty ? (
        <Text></Text>
      ) : answer ? (
        answer.map((option) => {
          if (option) {
            return (
              <View style={{ flexDirection: "row" }}>
                <Text style={{ paddingRight: "10" }}>
                  Impact Area:{" "}
                  {option.impactarea
                    ? option.impactarea.impactname
                    : option.impactareaname}
                </Text>
                <Text style={{ paddingRight: "10" }}>
                  Focus Area:{" "}
                  {option.focusarea ? option.focusarea.name : option.genomename}
                </Text>
                <Text style={{ padding: "0" }}>
                  Outcome: {option.outcome ? option.outcome.name : option.name}
                </Text>
              </View>
            );
          } else {
            return <Text>Ups!</Text>;
          }
        })
      ) : (
        <Text></Text>
      )}
    </>
  );
};

export default TargetedOutcomes;
