import React from "react";

function Search(props) {
  const { placeholder = "", handleChange = () => {}, value = "" } = props;
  return (
    <div>
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative rounded-full shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-800"
            style={{ width: "24px", height: "24px" }}
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
            />
          </svg>
        </div>
        <input
          id="search"
          className="form-input block w-full pl-10 sm:text-xs sm:leading-5 rounded-full"
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
          value={value}
        />
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-800 hover:text-red-600"
          onClick={() => handleChange("")}
        >
          X
        </div>
      </div>
    </div>
  );
}

export default Search;
