import React from "react";
import { Text, View } from "@react-pdf/renderer";
import ComponentChooser from "../ComponentChooser";
import styles from "../../styles";

const YesNoConditional = (props) => {
  const { object, empty } = props;
  const subquestions = object.subquestions;

  // They selected yes for this yes/no conditional if value is 1
  if (object.responsevalue == "Yes") {
    return (
      <>
        <Text style={styles.question}>
          {object.questionnumber + ". " + object.questionbody}
        </Text>
        <Text>{empty ? "" : "Yes"}</Text>
        <View style={{ marginLeft: 5, marginTop: 5, flexDirection: "column" }}>
          {subquestions.map((subquestion) => {
            return (
              <ComponentChooser
                type={subquestion.inputtype}
                object={subquestion}
              />
            );
          })}
        </View>
      </>
    );
  } else {
    return (
      <>
        <Text style={styles.question}>
          {object.questionnumber + ". " + object.questionbody}
        </Text>
        <Text>{empty ? "" : "No"}</Text>
      </>
    );
  }
};

export default YesNoConditional;
