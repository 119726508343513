import React from "react";
import { Link, useHistory, Prompt } from "react-router-dom";
import useApiRequest from "../hooks/useApiRequest";
import useOutsideClick from "../hooks/useOutsideClick";
import useUnload from "../hooks/useUnload";
import { GlobalNav, Header, Main } from "../templates/Layout";
import SectionSelect from "./SectionSelect";
import { ModalBody, ModalButton, ModalParent } from "../elements/Modal";
import Dropdown from "../elements/TailwindDropdown";
import Button from "../elements/Button";
import { Sidebar } from "../templates/Layout";
import LoadingIcon from "../elements/LoadingIcon";

function BeneficiaryAndClassification({
  selectedBeneficiary,
  selectedClassification,
  beneficiaryTypes,
  classificationTypes,
  setNewProgramState,
  primaryOutcome,
  setPrimaryOutcome,
}) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(null);
  const ref = React.useRef();
  useOutsideClick(ref, () => setIsOpen(false));
  const [originalState, setOriginalState] = React.useState({
    primarybeneficiary: selectedBeneficiary,
    classification: selectedClassification,
    primaryoutcome: primaryOutcome,
  });

  const handleShow = (value) => (currentState) => {
    if (currentState) {
      setIsOpen(value);
    }
  };
  const handleCancel = () => {
    setNewProgramState({ ...originalState });
    setIsEdit(false);
    setIsOpen(null);
  };
  const handleSave = () => {
    setOriginalState({
      primarybeneficiary: selectedBeneficiary,
      classification: selectedClassification,
      primaryoutcome: primaryOutcome,
    });
    setIsEdit(false);
    setIsOpen(null);
  };
  const isDisabled = (type) => {
    if (
      selectedClassification?.programclassificationid === 1 &&
      type.beneficiarytypeid === 3
    ) {
      if (selectedBeneficiary?.beneficiarytypeid === 3) {
        setNewProgramState({ primarybeneficiary: null });
      }
      return true;
    } else if (
      selectedClassification?.programclassificationid === 4 &&
      type.beneficiarytypeid !== 3
    ) {
      if (
        selectedBeneficiary?.beneficiarytypeid &&
        selectedBeneficiary?.beneficiarytypeid !== 3
      ) {
        setNewProgramState({ primarybeneficiary: null });
      }
      return true;
    } else return false;
  };

  const handleCheckPrimaryOutcome = (beneficiary) => {
    if (
      primaryOutcome?.beneficiarytypes &&
      !primaryOutcome.beneficiarytypes.some(
        (type) => type.beneficiarytypeid === beneficiary.beneficiarytypeid
      )
    ) {
      setPrimaryOutcome({});
    }
  };
  return (
    <div ref={ref}>
      {!isEdit && (
        <div
          className="text-right w-full flex justify-end items-center cursor-pointer"
          onClick={() => setIsEdit(true)}
        >
          <svg className="w-4 h-4 fill-current " viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
            />
          </svg>
          <div className="font-semibold ml-1 mr-5 upp">Edit</div>
        </div>
      )}
      {isEdit ? (
        <div>
          <div className="mb-2 mr-4">
            <Dropdown
              label="Selected Classification"
              value={selectedClassification?.name}
              options={classificationTypes}
              show={isOpen === 0}
              handleShow={(e) => handleShow(0)(e)}
              handleChange={(e) => setNewProgramState({ classification: e })}
            />
          </div>
          <div className="mr-4">
            <Dropdown
              label="Selected Beneficiary"
              value={selectedBeneficiary?.name}
              options={beneficiaryTypes}
              show={isOpen === 1}
              handleShow={(e) => handleShow(1)(e)}
              handleChange={(e) => {
                setNewProgramState({ primarybeneficiary: e });
                handleCheckPrimaryOutcome(e);
              }}
              isDisabled={isDisabled}
              placeholder="Select a beneficiary type"
            />
          </div>
          <div className="flex flex-no-wrap justify-end pr-4 mt-2">
            <Button
              className="mr-2"
              color="gray"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button color="green" onClick={() => handleSave()}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-2 mr-4">
            <div className="block leading-5 font-semibold text-gray-500 uppercase">
              Program Classification
            </div>
            <div>{selectedClassification?.name}</div>
          </div>
          <div className="mr-4">
            <div className="block leading-5 font-semibold text-gray-500 uppercase">
              Program Beneficiary Type
            </div>
            <div>{selectedBeneficiary?.name}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function SelectedOutcomes({
  primaryOutcome,
  setPrimaryOutcome,
  selectedBeneficiaryId,
  selectedOutcomes,
  showAlert,
}) {
  return (
    <div className="flex flex-wrap w-full mb-6">
      {selectedOutcomes.map((outcome, index) => {
        const selected = outcome.outcomeid === primaryOutcome?.outcomeid;
        const disabled = !outcome.beneficiarytypes.some(
          (type) => type.beneficiarytypeid === selectedBeneficiaryId
        );

        return (
          <React.Fragment key={index}>
            <div
              className={`
                ${
                  disabled
                    ? "border bg-disabled text-gray-400 cursor-not-allowed"
                    : "border"
                }
                ${
                  selected
                    ? "border-2 border-blue-500 bg-blue-500 bg-opacity-5"
                    : "border"
                } shadow-sm rounded md:w-56 lg:w-72 mr-4 mt-2 mb-2 p-4 cursor-pointer relative`}
              onClick={() =>
                !disabled ? setPrimaryOutcome(outcome) : showAlert()
              }
            >
              {selected && (
                <div className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 rounded-full bg-blue-500 flex justify-center items-center">
                  <svg
                    className="inline-block"
                    width="11"
                    height="9"
                    fill="#4D93B8"
                    viewBox="0 0 11 9"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2271 2.17576L10.3257 2.11685L9.16378 0.954468L4.10145 5.99704L1.83607 3.73166L0.674194 4.87424L4.10138 8.30143L10.2271 2.17576Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              <div
                className={`text-tiny uppercase tracking-wider ${
                  disabled ? "text-gray-400" : "text-gray-500"
                } mb-2 `}
              >
                {`${outcome.impactareaname} > ${outcome.genomename}`}
              </div>
              <div className="font-semibold">{outcome?.name}</div>
              <div className="text-xs mb-8">{outcome?.description}</div>
              <div
                className={`absolute bottom-0 right-0 text-tiny mt-4 p-4 ${
                  disabled ? "text-gray-400" : "text-gray-500"
                }`}
              >{`Outcome ID: ${outcome?.outcomecode}`}</div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

function SelectPrimaryOutcome(props) {
  const {
    cookieData,
    setNewProgramState,
    newProgramState,
    selectedOutcomes,
    sections,
    handleSectionEnable,
    handleCancel,
  } = props;

  const {
    primarybeneficiary,
    classification,
    primaryoutcome,
  } = newProgramState;
  const [isAlert, setIsAlert] = React.useState(false);

  const { state: beneficiaryTypes } = useApiRequest({
    reqType: "getBeneTypes",
  });
  const { state: classificationTypes } = useApiRequest({
    reqType: "getClassifications",
  });
  React.useEffect(() => {
    (() => {
      if (sections[4].disabled) {
        handleSectionEnable(4);
      }
    })();
  }, []);
  const history = useHistory();
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  return (
    <>
      <Prompt
        message={(params) => {
          return params.pathname.includes("createprogram")
            ? true
            : "You may have unsaved data. Are you sure you want to leave?";
        }}
      />
      <div className="bg-purple-800 pb-14">
        <GlobalNav cookieData={cookieData} />
        <Header>
          <div className="flex flex-nowrap justify-between w-full items-start">
            <div>
              <div>
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                  {props.isEdit ? "Edit Program" : "Add New Program"}
                </h2>

                <div className="text-white">
                  Your inventory of social programs or projects.
                </div>
              </div>
            </div>
            <SectionSelect sections={props.sections} currentSection={4} />
          </div>
        </Header>
      </div>
      <Main>
        <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
          <div className="">Select Primary Outcome</div>
          <div className="">Step 5 of 7</div>
        </div>
        <hr className="-mx-6" />
        <div className="flex">
          <Sidebar className="-mb-6">
            <div className="-mx-6"></div>
            <div className="mt-2 text-sm">
              {beneficiaryTypes.data && classificationTypes.data ? (
                <BeneficiaryAndClassification
                  beneficiaryTypes={beneficiaryTypes.data}
                  classificationTypes={classificationTypes.data}
                  selectedBeneficiary={primarybeneficiary}
                  selectedClassification={classification}
                  setNewProgramState={setNewProgramState}
                  primaryOutcome={primaryoutcome}
                  setPrimaryOutcome={(value) =>
                    setNewProgramState({
                      primaryoutcome: value,
                    })
                  }
                />
              ) : (
                <LoadingIcon />
              )}
            </div>
          </Sidebar>
          <div className=" bg-purple-100 -mx-6 -mb-6">
            {beneficiaryTypes.data && classificationTypes.data ? (
              <div className="w-full mx-auto  px-6 pb-6   bg-white">
                <div className="max-w-2xl pt-8">
                  <div>
                    Please identify the social outcome that your program is most
                    focused on producing within a typical year.
                  </div>{" "}
                  <div className="mt-4">
                    Programs may be designed to produce many short and long-term
                    outcomes. Please identify the one outcome that your program
                    design is most focused on achieving for your beneficiaries
                    with a typical year/program cycle.
                  </div>
                </div>
                <p className="my-4 max-w-2xl text-sm italic">
                  Outcomes that do not correspond with your selected program
                  beneficiary type are disabled.
                </p>

                <div className="mb-6">
                  <SelectedOutcomes
                    primaryOutcome={primaryoutcome}
                    setPrimaryOutcome={(value) =>
                      setNewProgramState({
                        primaryoutcome: value,
                      })
                    }
                    selectedBeneficiaryId={
                      primarybeneficiary?.beneficiarytypeid
                    }
                    selectedOutcomes={selectedOutcomes}
                    classificationTypes={classificationTypes.data}
                    selectedClassificationId={
                      classification?.programclassificationid
                    }
                    showAlert={() => setIsAlert(true)}
                  />
                </div>

                <hr className="mt-10 border" />
                <div className="flex justify-between my-6">
                  <Button
                    color="gray"
                    className="justify-self-start text-right w-48"
                    onClick={() => handleCancel()}
                  >{`Cancel`}</Button>
                  <div className="flex justify-end">
                    <Link to="/createprogram/selectoutcomes">
                      <Button
                        color="blue"
                        className="text-right w-48 mr-8"
                      >{`Previous`}</Button>
                    </Link>

                    <Button
                      color="green"
                      className="text-right w-48"
                      onClick={() =>
                        history.push("/createprogram/selectsecondaryoutcomes")
                      }
                      disabled={!newProgramState.primaryoutcome}
                    >{`Save & Continue`}</Button>
                  </div>
                </div>
                {/* <div className="flex justify-end my-6">
                  <Link to="/createprogram/selectoutcomes">
                    <Button
                      color="gray"
                      className="text-right w-48 mr-8"
                    >{`Previous`}</Button>
                  </Link>
                  <Button
                    color="green"
                    className="text-right w-48"
                    onClick={() =>
                      history.push("/createprogram/selectsecondaryoutcomes")
                    }
                    disabled={!newProgramState.primaryoutcome}
                  >{`Save & Continue`}</Button>
                </div> */}
              </div>
            ) : (
              <LoadingIcon />
            )}
            <ModalParent isOpen={isAlert}>
              <ModalBody alert title={`Option not available`}>
                <p className="">
                  {primarybeneficiary?.name === "Organizations"
                    ? "This outcome is not designed for programs working with beneficiaries who are organizations. Please select a different outcome. If this is your primary outcome, change your selected classification and beneficiary type on the left."
                    : "This outcome is designed for programs working with beneficiaries who are organizations. Please select a different outcome. If this is your primary outcome, change your selected classification and beneficiary type on the left."}
                </p>
              </ModalBody>
              <div className="flex flex-no-wrap justify-center">
                <ModalButton text="Close" onClick={() => setIsAlert(false)} />
              </div>
            </ModalParent>
          </div>
        </div>
      </Main>
    </>
  );
}

export default SelectPrimaryOutcome;
