export default function reqReducer(state, action) {
  switch (action.type) {
    case "clearState": {
      return null;
    }
    case "startRequest": {
      return { ...state, isLoading: true, isError: false };
    }
    case "successRequest": {
      return { ...state, isLoading: false, isError: false, data: action.data };
    }
    case "failRequest": {
      return { ...state, isLoading: true, isError: true };
    }
    default:
      throw new Error("Something went wrong. Please reload and try again");
  }
}
