import React from "react";
import { Transition } from "@headlessui/react";
import useOutsideClick from "../hooks/useOutsideClick";

function ActionButton({ handleClick, options, customOption, width = "w-24" }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef();
  useOutsideClick(ref, () => setIsOpen(false));
  return (
    <div className={`inline-block text-left ${width} z-50`} ref={ref}>
      <div className="w-full">
        <span className="w-full shadow-sm">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-sm border border-gray-300  bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="flex flex-no-wrap w-full">
              <div className="border-r border-gray-300 px-3 py-2 justify-self-stretch w-full ">
                Action
              </div>
              {/* Heroicon name: chevron-down */}
              <div className="px-1 py-2 w-8 flex-none">
                <svg
                  className="  h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </button>
        </span>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={`${width} origin-top-right absolute right-0 mt-2 rounded-md shadow-lg z-20`}
        >
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options &&
                options.map((option, index) => {
                  if (option.value === "custom") {
                    return (
                      <React.Fragment key={index}>
                        {customOption}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <a
                          className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer"
                          role="menuitem"
                          onClick={() => handleClick(option)}
                        >
                          {option.label}
                        </a>
                      </React.Fragment>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default ActionButton;
