import React from "react";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import igpLogo from "../../assets/icons/igp_logo-title.png";

function RegistrationForm() {
  return (
    <div className="bg-white w-full shadow-sm border border-white rounded py-8">
      <Input placeholder="" label="Organization Name" className="mb-4" />
      <Input placeholder="" label="Tax ID" className="mb-4" />
      <Input placeholder="" label="Email" className="mb-4" />
      <Input placeholder="" label="First Name" className="mb-4" />
      <Input placeholder="" label="Last Name" className="mb-4" />
      <Input placeholder="" label="Password" className="mb-4" />
      <Input placeholder="" label="Confirm Password" className="mb-4" />
      <div className="px-8 flex items-center justify-center bg-white">
        <Button className="w-full" color="green">
          Register
        </Button>
      </div>
    </div>
  );
}

function Registration() {
  return (
    <div className="max-w-sm mx-auto py-12  ">
      <div className="w-40  mx-auto">
        <img className="h-auto" src={igpLogo} alt="IGP Logo" />
      </div>
      <div className="mx-auto w-full mt-12 mb-6">
        <h2 className="text-center text-3xl font-bold mb-2">
          Create your account
        </h2>
        <div className="text-center">
          Please complete the information below to create an Impact Genome
          account.
        </div>
      </div>
      <div>
        <RegistrationForm />
      </div>
      <div className="w-100 text-center mt-2 text-xs">{`Privacy & Terms`}</div>
    </div>
  );
}

export default Registration;
