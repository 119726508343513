import React from "react";
import useApiRequest from "../hooks/useApiRequest";
import DateRange from "../elements/dateRange/DateRange";
import { ModalParent, ModalBody, ModalButton } from "../elements/Modal";
import { isAfter, isLeapYear, differenceInDays } from "date-fns";

function CreateReport({
  isCreateReport,
  setIsCreateReport,
  programid,
  userid,
}) {
  const intialState = {
    startdate: "",
    enddate: "",
  };
  const [reportDates, setReportDates] = React.useState(intialState);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  React.useEffect(() => {
    const checkReportingDates = () => {
      if (reportDates.startdate && reportDates.enddate) {
        const endDate = new Date(reportDates.enddate);
        const startDate = new Date(reportDates.startdate);

        const yearDiff = differenceInDays(endDate, startDate);
        if (isAfter(startDate, endDate)) {
          setErrorMessage(
            "Please select an End Date that is after the Start Date."
          );
        } else if (isLeapYear(startDate) || isLeapYear(endDate)) {
          yearDiff < 183 || yearDiff > 366
            ? setErrorMessage(
                "Please select a date range between 6 months and 12 months"
              )
            : setErrorMessage(false);
        } else if (
          (!isLeapYear(startDate) && !isLeapYear(endDate) && yearDiff < 182) ||
          yearDiff > 365
        ) {
          setErrorMessage(
            "Please select a date range between 6 months and 12 months"
          );
        } else setErrorMessage(false);
      }
    };
    checkReportingDates();
  }, [reportDates]);
  const handleDateSelect = (type) => (date) => {
    setReportDates((prev) => ({ ...prev, [type]: date }));
  };
  const handleSubmit = async () => {
    const submitResponse = await fetchDataOnDemand({
      reqType: "addProgramReport",
      body: { reportingdates: { ...reportDates }, programid, userid },
    });

    window.location = `http://hub-dev.impactgenome.org/report?programreportuuid=${submitResponse.programreportuuid}`;
  };
  return (
    <ModalParent isOpen={isCreateReport} maxWidth="max-w-md">
      <ModalBody title="Create New Report" noIcon>
        <div className="text-left sm:w-10/12 mx-auto mt-4 text-gray-800">
          <p className="mb-4">Time period for which you are reporting:</p>
          <div className="flex justify-between">
            <div>
              <div className="font-semibold text-gray-800 mb-1">Start Date</div>
              <DateRange
                value={reportDates.startdate}
                handleChange={(e) => handleDateSelect("startdate")(e)}
                placeholder="MM/DD/YYYY"
              />
            </div>
            <div>
              <div className="font-semibold text-gray-800 mb-1">End Date</div>
              <DateRange
                value={reportDates.enddate}
                handleChange={(e) => handleDateSelect("enddate")(e)}
                placeholder="MM/DD/YYYY"
              />
            </div>
          </div>
          <div className="h-4 text-xs text-red-700">
            {errorMessage && errorMessage}
          </div>
          <div className="text-xs italic mt-4">
            Please report on a complete/previous program cycle. Impact Reports
            summarize observed programs results and can not include projected
            data. The dates range must be between 6 months and 12 months.
          </div>
        </div>
      </ModalBody>
      <div className="flex flex-no-wrap justify-end">
        <ModalButton
          text="Cancel"
          onClick={() => {
            setIsCreateReport(false);
            setReportDates(intialState);
          }}
        />
        <ModalButton
          text="Create Report"
          className="ml-2"
          color="green"
          disabled={
            !!errorMessage ||
            !reportDates.startdate.length ||
            !reportDates.enddate.length
          }
          onClick={() => {
            setIsCreateReport(false);
            handleSubmit();
          }}
        />
      </div>
    </ModalParent>
  );
}

export default CreateReport;
