import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../../styles";
import globalHelper from "../../helpers/globalVars";

const SecondaryReach = (props) => {
  const { globaldata, object, empty } = props;
  const secondaryOutcomes = globalHelper.getValueByFieldName(
    "secondaryoutcomes",
    globaldata
  );
  const answer = object.responsevalue ? object.responsevalue : [];

  var outcomes = [];
  // match to get outcome names from globaldata
  for (var i = 0; i < answer.length; i++) {
    for (var j = 0; j < secondaryOutcomes.length; j++) {
      if (
        answer[i].id === secondaryOutcomes[j].outcome
          ? secondaryOutcomes[j].outcome.outcomeid
          : secondaryOutcomes[j].outcomeid
      ) {
        outcomes.push({
          genome: secondaryOutcomes[j].outcome
            ? secondaryOutcomes[j].outcome.genomename
            : secondaryOutcomes[j].genomename,
          outcomename: secondaryOutcomes[j].outcome
            ? secondaryOutcomes[j].outcome.name
            : secondaryOutcomes[j].name,
          beneficiarytypename: answer[i].inputValues.beneficiary
            ? answer[i].inputValues.beneficiary.beneficiarytypename
            : "N/A",
          achievednumber: answer[i].inputValues.achievednumber,
          outofnumber: answer[i].inputValues.outofnumber,
          rank: secondaryOutcomes[j].rank ? secondaryOutcomes[j].rank : j,
        });
      }
    }
  }

  outcomes.sort((a, b) => {
    if (a.rank) {
      if (a.rank > b.rank) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return;
    }
  });
  return (
    <>
      <Text style={styles.question}>
        {object.questionnumber +
          ". " +
          "Please complete the following impact statement by inserting your reach and outcomes."}
      </Text>
      {empty ? (
        <Text></Text>
      ) : outcomes.length ? (
        outcomes.map((outcome) => (
          <View>
            <Text style={{ fontWeight: "bold" }}>{outcome.outcomename}</Text>
            <Text>
              {outcome.achievednumber +
                " out of " +
                outcome.outofnumber +
                " " +
                outcome.beneficiarytypename}
            </Text>
          </View>
        ))
      ) : (
        <Text></Text>
      )}
    </>
  );
};

export default SecondaryReach;
