import React from "react";
import { useHistory, Link } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { GlobalNav, Header, Main, Sidebar } from "../templates/Layout";
import LoadingIcon from "../elements/LoadingIcon";
import useApiRequest from "../hooks/useApiRequest";
import Button from "../elements/Button";
import igpLogo from "../assets/icons/igp_logo.png";
import ProgramListItem from "../components/ProgramListItem";

function Organization(props) {
  const { cookieData } = props;
  let history = useHistory();
  const { parsedQuery } = useQuery();
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);
  const [programs, setPrograms] = React.useState(null);

  const { state: organization } = useApiRequest({
    reqType: "getOrganizations",
    parameters: `?organizationuuid=${
      parsedQuery.organizationuuid
        ? parsedQuery.organizationuuid
        : cookieData.organizations[0].organizationuuid
    }`,
  });
  React.useEffect(() => {
    organization.data && setSelectedOrganization(organization.data[0]);
    organization.data && setPrograms(organization.data[0].programs);
  }, [organization]);
  return (
    <>
      {selectedOrganization ? (
        <>
          <div className="bg-purple-800 pb-14">
            <GlobalNav cookieData={props.cookieData} />
            <Header>
              <div className="flex flex-nowrap justify-between w-full items-start">
                <div>
                  <h1 className="text-white text-2xl font-bold leading-none">
                    Programs
                  </h1>
                  <div className="text-white">
                    Your inventory of social programs or projects.
                  </div>
                </div>
                <div>
                  <Link to="/createprogram/overview">
                    <Button color="green">Add Program</Button>
                  </Link>
                </div>
              </div>
            </Header>
          </div>
          <Main>
            <div className="flex">
              <Sidebar className="-my-6">
                <div className="mx-auto w-48">
                  <img src={igpLogo} alt="" />
                </div>
                <div className="mx-auto w-56">
                  <div className="text-xl font-semibold mt-4">
                    {selectedOrganization.name}
                  </div>
                  <div className="mt-2 text-sm font-semibold">Tax ID</div>
                  <div className="mt-1 mb-1 text-sm">
                    {selectedOrganization?.taxid}
                  </div>
                  <div className="mt-2 text-sm font-semibold">Address</div>
                  <div className="mt-1 mb-1 text-sm">ADRESS PLACEHOLDER</div>
                </div>
              </Sidebar>
              <div className="w-full">
                {programs.map((program, index) => (
                  <React.Fragment key={index}>
                    <ProgramListItem program={program} />
                    {index !== selectedOrganization.programs.length - 1 && (
                      <hr />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Main>
        </>
      ) : (
        <LoadingIcon color="white" />
      )}
    </>
  );
}

export default Organization;
