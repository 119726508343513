import React from "react";
import useIsMounted from "../hooks/useIsMounted";

function Textarea(props) {
  const {
    label = "",
    placeholder = "test",
    id = "",
    className = "",
    maxLength = 550,
    value = "",
    handleChange = () => {},
  } = props;
  // const [state, setState] = React.useState(!value ? "" : value);
  // const isMounted = useIsMounted();
  // React.useEffect(() => {
  //   isMounted && handleChange(state);
  // }, [state, value]);

  return (
    <div className={className}>
      <div className="flex items-center justify-center bg-white">
        <div className="w-full mx-auto">
          {label && (
            <label
              htmlFor={id}
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              {label}
            </label>
          )}
          <div className="mt-1 relative rounded-md shadow-sm">
            <textarea
              className="form-input block w-full sm:text-sm sm:leading-5 placeholder-gray-400"
              rows="5"
              maxLength={maxLength}
              onChange={(e) => handleChange(e.target.value)}
              value={!value ? "" : value}
            />
          </div>
          <p className="text-right">{`${
            value ? value.length : ""
          } / ${maxLength}`}</p>
        </div>
      </div>
    </div>
  );
}

export default Textarea;
