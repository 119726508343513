import React from "react";

export default function Input(props) {
  const {
    label = "",
    placeholder = "test",
    id = "",
    className = "",
    number = false,
    handleChange = () => {},
    value = "",
  } = props;

  const [input, setInput] = React.useState(value);

  React.useEffect(() => {
    function doChange() {
      handleChange(input);
    }
    doChange();
  }, [input]);
  const preventNonNumber = (e) => {
    if (!/[0-9]/.test(parseInt(e.key)) && e.key !== "") {
      e.preventDefault();
    }
  };
  return (
    <div className={className}>
      <div className="flex items-center justify-center bg-white">
        <div className="w-full mx-auto">
          {label && (
            <label
              htmlFor={id}
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              {label}
            </label>
          )}
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id={id}
              className="form-input block w-full sm:text-sm sm:leading-5 placeholder-gray-400"
              placeholder={placeholder}
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              onKeyPress={(e) => number && preventNonNumber(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
