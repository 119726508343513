import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../styles";

import Subsection from "./Subsection";

const Section = (props) => {
  const { globaldata, section, number, empty } = props;

  return (
    <>
      <View style={styles.subsection}>
        <Text
          style={{ fontFamily: "Helvetica-Bold", fontSize: 9, marginBottom: 5 }}
        >
          {number + ". " + section.sectionname}
        </Text>
        {section.subsections.map((subsection, i) => {
          return (
            <Subsection
              globaldata={globaldata}
              subsection={subsection}
              number={i}
              sectionIndex={number}
              empty={empty}
            />
          );
        })}
      </View>
    </>
  );
};

export default Section;
