function getValueByFieldName(name, data) {
  switch (name) {
    case "programname":
      return data.programname;
    case "organizationname":
      return data.organizationname;
    case "location":
      return data.locations
        ? data.locations.map((location) => location.name).join(", ")
        : null;
    case "primarybeneficiary":
      return data.primarybeneficiary ? data.primarybeneficiary.name : null;
    case "classification":
      return data.classification ? data.classification.name : null;
    case "primaryoutcome":
      return data.primaryoutcome.name;
    case "secondaryoutcomes":
      return data.secondaryoutcomes;
    case "reportingdates":
      return {
        start: data.reportingdates.startdate,
        end: data.reportingdates.enddate,
      };
      return;
    default:
      return;
  }
}

export default {
  getValueByFieldName,
};
