import React from "react";
import useIsMounted from "../hooks/useIsMounted";

import Select, { components } from "react-select";
import { black } from "@tailwindcss/ui/colors";

const CustomOptions = (props) => {
  const { data, innerRef, innerProps } = props;
  return data.custom ? (
    <div ref={innerRef} {...innerProps}>
      {props.selectProps.customOptions}
    </div>
  ) : (
    <components.Option {...props} />
  );
};

function Dropdown(props) {
  const {
    type = "",
    id = "",
    isDisabled = false,
    handleChange = () => {},
    value = "",
    isLoading = false,
    placeholder = "",
    hideSelectedOptions = "",
    options = [],
    customOptions = false,
    classNamePrefix,
    className = "",
    grouped = false,
    closeMenuOnSelect = true,
    isMulti = false,
  } = props;

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  //custom styles
  const DropdownIndicator = (props) => {
    return (
      <div>
        <components.DropdownIndicator {...props}>
          <svg className="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
          </svg>
        </components.DropdownIndicator>
      </div>
    );
  };
  const indicatorSeparatorStyle = {
    alignSelf: "stretch",
    backgroundColor: "#e2e8f0",
    marginBottom: 0,
    marginTop: 0,
    width: 1,
  };

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: "#cbd5e0",
    }),
  };

  const [selectedValue, setSelectedValue] = React.useState(value);
  React.useEffect(() => {
    (function () {
      selectedValue && handleChange(selectedValue);
    })();
  }, [selectedValue]);
  return (
    <Select
      name={type}
      id={id}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onChange={(e) => setSelectedValue(e)}
      value={selectedValue}
      options={options}
      placeholder={placeholder}
      hideSelectedOptions={hideSelectedOptions}
      // components={customOptions ? { Option: CustomOptions } : null}
      classNamePrefix={classNamePrefix}
      formatGroupLabel={grouped ? formatGroupLabel : null}
      components={{ DropdownIndicator, IndicatorSeparator }}
      styles={customStyles}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      // {...props}
    />
  );
}

export default Dropdown;
