import LogRocket from "logrocket";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Registration from "./registration/pages/Registration";
import Organization from "./pages/Organization";
import CreateProgram from "./pages/CreateProgram";
import Program from "./pages/Program";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import auth from "./helpers/auth";
function App() {
  const { isLoggedIn, getDecodedTokenInfo } = auth();

  const logUser = () => {
    if (isLoggedIn()) {
      const userInfo = getDecodedTokenInfo();
      LogRocket.identify(userInfo.useruuid, {
        name: userInfo.firstname + " " + userInfo.lastname,
        email: userInfo.email,
      });
    }
  };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const test = isLoggedIn();
        return isLoggedIn() ? (
          <Component {...props} cookieData={getDecodedTokenInfo()} />
        ) : (
          <Login
            redirect={`${props.location.pathname}${props.location.search}`}
          />
        );
      }}
    />
  );
  logUser();

  return (
    <Router basename="hub-v2">
      <div className="min-h-screen bg-gray-100 text-gray-800">
        <Switch>
          <PrivateRoute path="/organization" component={Organization} />
          <PrivateRoute
            path="/createprogram/:createProgramPage"
            component={CreateProgram}
          />
          <PrivateRoute path="/program" component={Program} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <PrivateRoute path="/" exact component={Organization} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
