import React from "react";
import spinner from "../assets/icons/spinner_transparent.gif";

const LoadingIcon = (props) => {
  const { size = "w-6", componentWidth = "w-full" } = props;
  return (
    <div className={`h-full relative flex items-center ${componentWidth}`}>
      <img
        src={spinner}
        alt="loading..."
        className={`block ${size} mx-auto `}
      />
    </div>
  );
};

export default LoadingIcon;
