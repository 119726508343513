import React from "react";
import {
  Currency,
  DateRange,
  YesNoRadio,
  YesNoConditional,
  SecondaryReach,
  Number,
  Textarea,
  Multiselect,
  Dropdown,
  Activites,
  PrimaryOutcome,
  TargetedOutcomes,
  PrimaryReach,
  Location,
  PercentInput,
  Percentage,
  DropdownWithTextArea,
  GeneImplementation,
  ListSelect,
  TopGenes,
} from "./Components";
import { Text } from "@react-pdf/renderer";
import CheckboxList from "./checkboxList/CheckboxList";

const ComponentChooser = (props) => {
  const { globaldata, type, object, empty } = props;
  switch (type) {
    //ignore list
    case "fileupload":
      return null;
    case "question-instructions":
      return null;
    //end of ignore list
    case "checkbox-list":
      return <CheckboxList object={object} empty={empty} />;
    case "currency":
      return <Currency object={object} empty={empty} />;
    case "daterange":
      return <DateRange object={object} empty={empty} />;
    case "yesnoradio":
      return <YesNoRadio object={object} empty={empty} />;
    case "primary-outcome":
      return <PrimaryOutcome object={object} empty={empty} />;
    case "targeted-outcomes":
      return <TargetedOutcomes object={object} empty={empty} />;
    case "textarea":
      return <Textarea object={object} empty={empty} />;
    case "activities":
      return <Activites object={object} empty={empty} />;
    case "top-genes":
      return <TopGenes object={object} empty={empty} />;
    case "number":
      return <Number object={object} empty={empty} />;
    case "primary-reach":
      return (
        <PrimaryReach globaldata={globaldata} object={object} empty={empty} />
      );
    case "listselect":
      return <ListSelect object={object} empty={empty} />;
    case "percentage":
      return <Percentage object={object} empty={empty} />;
    case "percentinput":
      return <PercentInput object={object} empty={empty} />;
    case "gene-implementation":
      return <GeneImplementation object={object} empty={empty} />;
    case "yesnoconditional":
      return <YesNoConditional object={object} empty={empty} />;
    case "location":
      return <Location object={object} empty={empty} />;
    case "multiselect":
      return <Multiselect object={object} empty={empty} />;
    case "dropdown":
      return <Dropdown object={object} empty={empty} />;
    case "secondary-reach":
      return (
        <SecondaryReach globaldata={globaldata} object={object} empty={empty} />
      );
    case "dropdown-with-textarea":
      return <DropdownWithTextArea object={object} empty={empty} />;
    default:
      return (
        <>
          <Text style={{ color: "blue" }}>{type + " not found"}</Text>
        </>
      );
  }
};

export default ComponentChooser;
