import React from "react";
import apiRequestReducer from "../reducers/apiRequestReducer";
import api from "../helpers/apiHelper";

const useApiRequest = ({
  reqType = "",
  parameters = "",
  body = "",
  isCallOnDemand = false,
  initialData = null,
  setIsLoading = true,
} = {}) => {
  // const [reqInfo, setReqInfo] = React.useState({
  //   reqType,
  //   parameters,
  //   body,
  //   isCallOnDemand,
  //   setIsLoading,
  // });

  const initialState = {
    isLoading: setIsLoading,
    isError: false,
    isCallOnDemand: null,
    data: initialData,
  };

  const [state, dispatch] = React.useReducer(apiRequestReducer, initialState);

  const fetchData = React.useCallback(
    async (parameters) => {
      dispatch({ type: "clearState" });
      dispatch({ type: "startRequest" });
      try {
        // const { reqType, body } = reqInfo;
        const result = await api[reqType]({ parameters, body });
        dispatch({ type: "successRequest", data: result.data });
      } catch (error) {
        console.error(error);
        dispatch({ type: "failRequest" });
      }
    },
    [parameters]
  );
  const fetchDataOnDemand = async ({
    reqType,
    body = "",
    parameters = "",
  } = {}) => {
    dispatch({ type: "clearState" });
    dispatch({ type: "startRequest" });
    try {
      // const { reqType, body } = reqInfo;
      const result = await api[reqType]({ parameters, body });
      dispatch({ type: "successRequest", data: result.data });
      return result.data;
    } catch (error) {
      console.error(error);
      dispatch({ type: "failRequest" });
    }
  };

  React.useEffect(() => {
    if (!isCallOnDemand && parameters !== "waiting") {
      fetchData(parameters);
      return () => {};
    }
  }, [fetchData, parameters]);
  return { state, fetchData, fetchDataOnDemand };
};

export default useApiRequest;
