import React from "react";
import { Link, Prompt } from "react-router-dom";
import useUnload from "../hooks/useUnload";
import { GlobalNav, Header, Main } from "../templates/Layout";
import SectionSelect from "./SectionSelect";
import { ModalBody, ModalButton, ModalParent } from "../elements/Modal";
import Button from "../elements/Button";

function SelectedOutcomes({
  primaryOutcome,
  secondaryOutcomes,
  setSecondaryOutcomes,
  selectedOutcomes,
  showAlert,
}) {
  return (
    <div className="flex flex-wrap w-full mb-6">
      {selectedOutcomes
        .filter((outcome) => outcome.outcomeid !== primaryOutcome.outcomeid)
        .map((outcome, index) => {
          const selected = secondaryOutcomes.some(
            (secondaryOutcome) =>
              secondaryOutcome.outcomeid === outcome.outcomeid
          );
          const disabled = secondaryOutcomes.length === 5 && !selected;
          return (
            <React.Fragment key={index}>
              <div
                className={`
                  ${
                    disabled
                      ? "border bg-disabled text-gray-400 cursor-not-allowed"
                      : "border"
                  }
                  ${
                    selected
                      ? "border-2 border-blue-500 bg-blue-500 bg-opacity-5"
                      : "border"
                  } shadow-sm rounded md:w-56 lg:w-72 mr-4 mt-2 mb-2 p-4 cursor-pointer relative`}
                onClick={() =>
                  !disabled ? setSecondaryOutcomes(outcome) : showAlert()
                }
              >
                {selected && (
                  <div className="absolute top-0 right-0 -mt-2 -mr-2 w-4 h-4 rounded-full bg-blue-500 flex justify-center items-center">
                    <svg
                      className="inline-block"
                      width="11"
                      height="9"
                      fill="#4D93B8"
                      viewBox="0 0 11 9"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2271 2.17576L10.3257 2.11685L9.16378 0.954468L4.10145 5.99704L1.83607 3.73166L0.674194 4.87424L4.10138 8.30143L10.2271 2.17576Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
                <div
                  className={`text-tiny uppercase tracking-wider ${
                    disabled ? "text-gray-400" : "text-gray-500"
                  } mb-2 `}
                >
                  {`${outcome.impactareaname} > ${outcome.genomename}`}
                </div>
                <div className="font-semibold">{outcome.name}</div>
                <div className="text-xs mb-8">{outcome.description}</div>
                <div
                  className={`absolute bottom-0 right-0 text-tiny mt-4 p-4 ${
                    disabled ? "text-gray-400" : "text-gray-500"
                  }`}
                >{`Outcome ID: ${outcome.outcomecode}`}</div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
}

function SelectSecondaryOutcomes(props) {
  const {
    cookieData,
    secondaryOutcomes,
    setSecondaryOutcomes,
    primaryOutcome,
    selectedOutcomes,
    sections,
    handleSectionEnable,
    handleCancel,
  } = props;
  const [isAlert, setIsAlert] = React.useState(false);
  React.useEffect(() => {
    (() => {
      if (sections[5].disabled) {
        handleSectionEnable(5);
      }
    })();
  }, []);
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  return (
    <>
      <Prompt
        message={(params) => {
          return params.pathname.includes("createprogram")
            ? true
            : "You may have unsaved data. Are you sure you want to leave?";
        }}
      />
      <div className="bg-purple-800 pb-14">
        <GlobalNav cookieData={cookieData} />
        <Header>
          <div className="flex flex-nowrap justify-between w-full items-start">
            <div>
              <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                {props.isEdit ? "Edit Program" : "Add New Program"}
              </h2>

              <div className="text-white">
                Your inventory of social programs or projects.
              </div>
            </div>
            <SectionSelect sections={props.sections} currentSection={5} />
          </div>
        </Header>
      </div>
      <Main>
        <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
          <div className="">Select Secondary Outcomes</div>
          <div className="">Step 6 of 7</div>
        </div>
        <hr className="-mx-6" />

        <div className="">
          <div className="w-full mx-auto  px-10    bg-white">
            <div className="max-w-2xl pt-8 mb-4">
              Please select up to 5 additional outcomes that your program is
              designed to support.
            </div>
            <div className="mb-6">
              <SelectedOutcomes
                primaryOutcome={primaryOutcome}
                secondaryOutcomes={secondaryOutcomes}
                setSecondaryOutcomes={setSecondaryOutcomes}
                selectedOutcomes={selectedOutcomes}
                showAlert={() => setIsAlert(true)}
              />
            </div>

            <hr className=" -mx-16 border" />
          </div>
          <div className="flex justify-between my-6">
            <Button
              color="gray"
              className="justify-self-start text-right w-48"
              onClick={() => handleCancel()}
            >{`Cancel`}</Button>
            <div className="flex justify-end">
              <Link to="/createprogram/selectprimaryoutcome">
                <Button
                  color="blue"
                  className="text-right w-48 mr-8"
                >{`Previous`}</Button>
              </Link>
              <Link to="/createprogram/review">
                <Button
                  color="green"
                  className="text-right w-48"
                >{`Save & Continue`}</Button>
              </Link>
            </div>
          </div>

          <ModalParent isOpen={isAlert}>
            <ModalBody alert title={`Option not available`}>
              <p className="">
                You have already selected the maximum amount of secondary
                outcomes. If you would like to select this outcome, please
                deselect one of your chosen secondary outcomes.
              </p>
            </ModalBody>
            <div className="flex flex-no-wrap justify-center">
              <ModalButton text="Close" onClick={() => setIsAlert(false)} />
            </div>
          </ModalParent>
        </div>
      </Main>
    </>
  );
}

export default SelectSecondaryOutcomes;
