import React from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import useOutsideClick from "../hooks/useOutsideClick";
import igpLogo from "../assets/icons/igp_logo.png";
import auth from "../helpers/auth";

export function GlobalNav({ cookieData }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOrgOpen, setIsOrgOpen] = React.useState(false);
  const { logout } = auth();
  const orgRef = React.useRef();
  const userRef = React.useRef();

  useOutsideClick(orgRef, () => setIsOrgOpen(false));
  useOutsideClick(userRef, () => setIsOpen(false));
  return (
    <nav className="bg-purple-800">
      <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
            >
              {/* Icon when menu is closed. */}
              {/* Menu open: "hidden", Menu closed: "block" */}
              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Icon when menu is open. */}
              {/* Menu open: "block", Menu closed: "hidden" */}
              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0">
              <div></div>
              <img
                className="block lg:hidden h-8 w-auto"
                src={igpLogo}
                alt="IGP Logo"
              />
              <img
                className="hidden lg:block h-8 w-auto"
                src={igpLogo}
                alt="IGP Logo"
              />
            </div>
            <div className="ml-3 relative">
              <div>
                <button
                  className="flex text-sm border-2 border-transparent rounded-full focus:outline-none  transition duration-150 ease-in-out"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                  onClick={() => setIsOrgOpen((prev) => !prev)}
                >
                  <div className="text-white flex flex-no-wrap items-center">
                    <div className="pr-1 text-lg font-bold">
                      {cookieData.organizations[0].name}
                    </div>
                    <div>
                      {isOrgOpen ? (
                        <svg
                          style={{ width: "24px", height: "24px" }}
                          viewBox="0 0 24 24"
                        >
                          <path fill="currentColor" d="M7,15L12,10L17,15H7Z" />
                        </svg>
                      ) : (
                        <svg
                          style={{ width: "24px", height: "24px" }}
                          viewBox="0 0 24 24"
                        >
                          <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                        </svg>
                      )}
                    </div>
                  </div>
                </button>
              </div>
              {/*
                  Profile dropdown panel, show/hide based on dropdown state.
      
                  For animated transitions, wrap the next element in this <Transition> component from https://gist.github.com/adamwathan/3b9f3ad1a285a2d1b482769aeb862467:
                */}

              <Transition
                show={isOrgOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
                  ref={orgRef}
                >
                  <div
                    className="py-1 rounded-md bg-white shadow-xs"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    {cookieData &&
                      !cookieData.isinternal &&
                      cookieData.organizations.map((org, index) => (
                        <React.Fragment key={index}>
                          <Link
                            to={`/organization?organizationuuid=${org.organizationuuid}`}
                            className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                            role="menuitem"
                          >
                            {org.name}
                          </Link>
                          <div className="border-t border-gray-100" />
                        </React.Fragment>
                      ))}
                    {/* <Link
                      to="/"
                      className="block px-4 py-2 text-xs text-center leading-5 text-gray-300 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Create New Organization
                    </Link> */}
                  </div>
                </div>
              </Transition>
            </div>

            <div className="hidden sm:block sm:ml-6">
              <div className="flex">
                <Link
                  to={`/organization`}
                  className="px-3 py-2 rounded-md text-sm font-medium leading-5 text-white bg-purple-900 active:outline-none active:text-white active:bg-purple-700 transition duration-150 ease-in-out"
                >
                  Programs
                </Link>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* Profile dropdown */}
            <div className="ml-3 relative">
              <div>
                <button
                  className="flex text-sm border-2 border-transparent rounded-full focus:outline-none  transition duration-150 ease-in-out"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                  onClick={() => setIsOpen((prev) => setIsOpen(!prev))}
                >
                  <div className="text-white flex flex-no-wrap items-center">
                    <div className="pr-1">{cookieData.email}</div>
                    <div>
                      {isOpen ? (
                        <svg
                          style={{ width: "24px", height: "24px" }}
                          viewBox="0 0 24 24"
                        >
                          <path fill="currentColor" d="M7,15L12,10L17,15H7Z" />
                        </svg>
                      ) : (
                        <svg
                          style={{ width: "24px", height: "24px" }}
                          viewBox="0 0 24 24"
                        >
                          <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                        </svg>
                      )}
                    </div>
                  </div>
                </button>
              </div>
              {/*
                  Profile dropdown panel, show/hide based on dropdown state.
      
                  For animated transitions, wrap the next element in this <Transition> component from https://gist.github.com/adamwathan/3b9f3ad1a285a2d1b482769aeb862467:
                */}

              <Transition
                show={isOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
                  ref={userRef}
                >
                  <div
                    className="py-1 rounded-md bg-white shadow-xs"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    {/* <a
                      href="/"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Your Profile
                    </a>
                    <a
                      href="/"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                    >
                      Settings
                    </a> */}
                    <a
                      href="https://www.impactgenome.org/"
                      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 cursor-pointer focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      role="menuitem"
                      onClick={() => logout()}
                    >
                      Sign out
                    </a>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
        <hr />
      </div>

      {/*
          Mobile menu, toggle classes based on menu state.
      
          Menu open: "block", Menu closed: "hidden"
        */}
      <div className="hidden sm:hidden">
        <div className="px-2 pt-2 pb-3">
          <a
            href="/"
            className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >
            Programs
          </a>
        </div>
      </div>
    </nav>
  );
}

export function Header({ children }) {
  // breadcrumbitems props will be array of objects.
  // object will be {text:[string], link:[string]}

  return (
    <header className="py-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div>{children}</div>
      </div>
    </header>
  );
}

export function Main({ children }) {
  return (
    <main className="-mt-14">
      <div className="max-w-6xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        {/* Replace with your content */}
        <div className="bg-white rounded-lg shadow-md px-5 py-6 sm:px-6">
          {children}
        </div>
        {/* /End replace */}
      </div>
    </main>
  );
}

export function Sidebar({ children, className }) {
  return (
    <div
      className={`hidden md:flex md:flex-shrink-0 w-84  -ml-6  rounded-l-lg ${className}`}
    >
      <div className="flex flex-col w-full  rounded-l-lg border-r border-gray-300 pt-5 pb-4 px-4 bg-purple-100">
        {/* <div className="mt-5 h-0 flex-1 flex flex-col overflow-y-auto">
          </div> */}
        {children}
      </div>
    </div>
  );
}
