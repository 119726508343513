import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import FileSaver from "file-saver";
import { ModalBody, ModalButton, ModalParent } from "../elements/Modal";
import { GlobalNav, Header, Main, Sidebar } from "../templates/Layout";
import verifiedLogo from "../assets/icons/verified.png";

import {
  DownloadResponses,
  generatePdf,
} from "../components/response-printer/App";
import {
  DownloadResponses as NewDownloadResponses,
  generatePdf as newGeneratePdf,
} from "../components/newResponsePrinter/App";
import useApiRequest from "../hooks/useApiRequest";
import useQuery from "../hooks/useQuery";
import Button from "../elements/Button";
import LoadingIcon from "../elements/LoadingIcon";
import ActionButton from "../elements/ActionButton";
import CreateReport from "../components/CreateReport";
import CloneReport from "../components/CloneReport";
import igpLogo from "../assets/icons/igp_logo.png";
import ProgramListItem from "../components/ProgramListItem";

const DownloadLink = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  const handleClick = async () => {
    setIsLoading(true);
    const response = await fetchDataOnDemand({
      reqType: "getSurvey",
      parameters: `?programreportuuid=${props.programreportuuid}`,
    });
    generatePdf({
      responseData: response.jsonformat,
      globalData: response.globalvariables,
    });
    setIsLoading(false);
  };
  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <div
      children="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer"
      onClick={() =>
        // generatePdf({
        //   responseData: state.data.jsonformat,
        //   globalData: state.data.globalvariables,
        // })
        handleClick()
      }
    >
      Responses PDF
    </div>
  );
};

const NewDownloadLink = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  const handleClick = async () => {
    setIsLoading(true);

    const response = await fetchDataOnDemand({
      reqType: "getSurvey",
      parameters: `?programreportuuid=${props.programreportuuid}`,
    });
    newGeneratePdf({
      responseData: response.jsonformat,
      globalData: response.globalvariables,
    });
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <div
      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer"
      onClick={() => handleClick()}
    >
      Responses PDF
    </div>
  );
};

function Program(props) {
  const { cookieData } = props;
  const { parsedQuery } = useQuery();
  let history = useHistory();
  let location = useLocation();

  const [program, setProgram] = React.useState(null);
  const [isDeleteAlert, setIsDeleteAlert] = React.useState({
    show: false,
    action: null,
    type: "",
  });
  const [isCreateReport, setIsCreateReport] = React.useState(
    location.state?.createreport ? location.state.createreport : false
  );
  const [isCloneReport, setIsCloneReport] = React.useState({
    show: false,
    clonereportid: null,
  });
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  const { state: programFetch } = useApiRequest({
    reqType: "getPrograms",
    parameters: `?programuuid=${parsedQuery.programuuid}`,
  });
  React.useEffect(() => {
    programFetch.data && setProgram(programFetch.data[0]);
  }, [programFetch]);

  const handleProgramActionClick = (e) => {
    if (e.label === "Edit Program") {
      history.push({ pathname: "/createprogram/overview", state: program });
    } else if (e.label === "Delete Program") {
      const action = () => {
        fetchDataOnDemand({
          reqType: "deleteProgram",
          parameters: `?programid=${program.programid}`,
        });
        console.log("Deleted!");
        history.push("/organization");
      };
      setIsDeleteAlert({ action, show: true, type: "program" });
    }
  };
  const handleReportActionClick = (e) => {
    if (e.label === "Edit Survey") {
      if (e.value.isoldversion) {
        history.push(`/report?programreportuuid=${e.value.programreportuuid}`);
      } else {
        window.location = `http://hub-dev.impactgenome.org/report?programreportuuid=${e.value.programreportuuid}`;
      }
    } else if (e.label === "Delete") {
      const action = () => {
        fetchDataOnDemand({
          reqType: "deleteProgramReport",
          parameters: `?programreportid=${e.value.programreportid}`,
        });
        console.log("Deleted!");
        const filteredReports = program.reports.filter(
          (report) => report.programreportid !== e.value.programreportid
        );
        setProgram((prev) => ({ ...prev, reports: filteredReports }));
        // globalDispatch({ type: "setReports", value: filteredReports });
      };
      setIsDeleteAlert({ action, show: true, type: "program report" });
    } else if (e.label === "Clone Report") {
      setIsCloneReport({ show: true, clonereportid: e.value.programreportid });
    } else if (e.label === "Scorecard") {
      FileSaver.saveAs(e.value.scorecardurl, "scorecard.pdf");
    }
  };
  const programButtonOptions = [
    { value: "Edit", label: "Edit Program" },
    { value: "Delete", label: "Delete Program" },
  ];
  const reportsButtonOptions = (report) => {
    switch (report.status) {
      case "Submitted":
        return [
          {
            value: report,
            label: "Clone Report",
            disabled: "yes",
          },
          { value: "custom" },
        ];
      case "Under Review":
        return [
          {
            value: report,
            label: "Clone Report",
            disabled: "yes",
          },
          { value: "custom" },
        ];
      case "Complete":
        return [{ value: report, label: "Clone Report" }, { value: "custom" }];
      case "Ready For Review":
        return [{ value: "custom" }];
      case "Migrated":
        return [{ value: report, label: "Clone Report" }];
      case "Verified":
        return [
          {
            value: report,
            label: "Scorecard",
            disabled: !report.scorecardurl ? "yes" : "no",
          },
          { value: report, label: "Clone Report" },
          { value: "custom" },
        ];
      case "New":
        return [
          { value: report, label: "Edit Survey" },
          { value: report, label: "Delete" },
        ];
      case "In-Progress":
        return [
          { value: report, label: "Edit Survey" },
          { value: report, label: "Delete" },
        ];
    }
  };
  const reportStatus = (status) => {
    switch (status) {
      case "In-Progress": {
        return { label: "In-Progress", color: "blue" };
      }
      case "New": {
        return { label: "In-Progress", color: "blue" };
      }
      case "Migrated": {
        return { label: "Migrated", color: "grey" };
      }
      case "Complete": {
        return { label: "Submitted", color: "purple" };
      }
      case "Ready For Review": {
        return { label: "Submitted", color: "purple" };
      }
      case "Under Review": {
        return { label: "Under Review", color: "purple" };
      }
      case "Verified": {
        return { label: "Verified", color: "green" };
      }
    }
  };
  return (
    <>
      {program ? (
        <>
          <div className="bg-purple-800 pb-14">
            <GlobalNav cookieData={props.cookieData} />
            <Header>
              <div className="flex flex-nowrap justify-between w-full items-start">
                <div>
                  <h1 className="text-white text-2xl font-bold leading-none">
                    {program.programname}
                  </h1>
                  <div className="text-white mt-1">
                    Your current and previous program impact reports.
                  </div>
                </div>
                <div className="w-40 relative font-medium">
                  <ActionButton
                    options={programButtonOptions}
                    handleClick={(e) => {
                      handleProgramActionClick(e);
                    }}
                    width="w-40"
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-start w-full items-start mt-4">
                <div className="mr-8">
                  <div className="uppercase text-xs text-gray-500 font-semibold">
                    primary classification
                  </div>
                  <div className="text-white">
                    {program.classification.name}
                  </div>
                </div>

                <div className="mr-8">
                  <div className="uppercase text-xs text-gray-500 font-semibold">
                    primary beneficiary
                  </div>
                  <div className="text-white">
                    {program.primarybeneficiary.name}
                  </div>
                </div>

                <div className="mr-8">
                  <div className="uppercase text-xs text-gray-500 font-semibold">
                    primary outcome
                  </div>
                  <div className="text-white">
                    {program.primaryoutcome.name}
                  </div>
                </div>

                <div className="mr-8">
                  <div className="uppercase text-xs text-gray-500 font-semibold">
                    operating location(s)
                  </div>
                  <div className="text-white">
                    {program.location
                      .map((location, index) =>
                        index === 0 ? location?.name : `, ${location?.name}`
                      )
                      .join("")}
                  </div>
                </div>

                <div className="mr-8">
                  <div className="uppercase text-xs text-gray-500 font-semibold">
                    discoverability
                  </div>
                  <div className="text-white">
                    {program.ispublic ? "Public" : "Private"}
                  </div>
                </div>
              </div>
            </Header>
          </div>
          <Main>
            <div className="w-full flex flex-no-wrap pb-6 justify-between items-center text-xl font-bold">
              <div className="">Impact Reports</div>
              <Button color="green" onClick={() => setIsCreateReport(true)}>
                Start Report
              </Button>
            </div>
            <hr className="-mx-6" />
            <div
              className="flex flex-col relative"
              //   style={{ minHeight: "500px" }}
            >
              <div className="-my-2 overflow-x-auto -mx-6 ">
                <div className="py-2 align-middle inline-block min-w-full ">
                  <div className="overflow-hidden border-b border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-purple-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Report ID
                          </th>
                          <th className="px-6 py-3 bg-purple-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            reporting period
                          </th>
                          <th className="px-6 py-3 bg-purple-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            created date
                          </th>
                          <th className="px-6 py-3 bg-purple-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            submitted date
                          </th>
                          <th className="px-6 py-3 bg-purple-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Impact Verified Date
                          </th>
                          <th className="px-6 py-3 bg-purple-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            status
                          </th>
                          <th className="px-6 py-3 bg-purple-100"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {program.reports.map((report, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                {report.reportigpuid}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                {`${report.reportingdates.startdate}-${report.reportingdates.enddate}`}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                {report.createddate}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                {report.submitteddate
                                  ? report.submitteddate
                                  : "-"}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5">
                                {report.impactverifieddate
                                  ? report.impactverifieddate
                                  : "-"}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 ">
                                {report.status === "Verified" ? (
                                  <div
                                    className="mx-auto"
                                    style={{ maxWidth: "120px" }}
                                  >
                                    <img
                                      src={verifiedLogo}
                                      alt="verified logo"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={`px-2 rounded-full bg-${
                                      reportStatus(report.status).color
                                    }-200 text-center font-semibold text-${
                                      reportStatus(report.status).color
                                    }-500`}
                                  >
                                    {reportStatus(report.status).label}
                                  </div>
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                                <ActionButton
                                  width="w-32"
                                  options={reportsButtonOptions(report)}
                                  handleClick={(e) =>
                                    handleReportActionClick(e)
                                  }
                                  customOption={
                                    report.isoldversion ? (
                                      <DownloadLink
                                        programreportuuid={
                                          report.programreportuuid
                                        }
                                      />
                                    ) : (
                                      <NewDownloadLink
                                        programreportuuid={
                                          report.programreportuuid
                                        }
                                      />
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                        {/* More rows... */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ModalParent isOpen={isDeleteAlert.show}>
                <ModalBody alert title={`Delete ${isDeleteAlert.type}!`}>
                  <p>
                    {` Are you sure you want to delete this ${isDeleteAlert.type}? This action
                    cannot be undone.`}
                  </p>
                </ModalBody>
                <div className="flex flex-no-wrap justify-end">
                  <ModalButton
                    text="Close"
                    onClick={() =>
                      setIsDeleteAlert((prev) => ({
                        ...prev,
                        show: false,
                        action: null,
                      }))
                    }
                  />
                  <ModalButton
                    text="Delete"
                    className="ml-2"
                    color="red"
                    onClick={() => {
                      isDeleteAlert.action();
                      setIsDeleteAlert((prev) => ({
                        ...prev,
                        show: false,
                        action: null,
                      }));
                    }}
                  />
                </div>
              </ModalParent>
              <CreateReport
                isCreateReport={isCreateReport}
                setIsCreateReport={setIsCreateReport}
                programid={program.programid}
                userid={cookieData.userid}
              />
              <CloneReport
                isCloneReport={isCloneReport.show}
                setIsCloneReport={() =>
                  setIsCloneReport({
                    show: false,
                    clonereportid: null,
                  })
                }
                programid={program.programid}
                userid={cookieData.userid}
                clonereportid={isCloneReport.clonereportid}
              />
            </div>
          </Main>
        </>
      ) : (
        <LoadingIcon color="white" />
      )}
    </>
  );
}

export default Program;
