import React from "react";
import useResponseContext from "../hooks/useResponseContext";
import useGlobalContext from "../hooks/useGlobalContext";
import globalVars from "../helpers/globalVars";
import surveyVars from "../helpers/surveyVars";

import styles from "../styles";
import { Text } from "@react-pdf/renderer";

import Metadata from "./metadata/Metadata";
import Section from "./sections/Section";
import Header from "./header/Header";
import Index from "./index/Index";

const SurveyPrinter = ({ data, globaldata, empty }) => {
  // const data = useResponseContext()
  // const globaldata = useGlobalContext()
  const responseData = data.jsonformat ? data.jsonformat : data;
  if (empty) {
    return (
      <>
        <Index structure={responseData} />
        {responseData.map((section, i) => {
          if (i == 0) {
            return;
          } else {
            return (
              <Section
                globaldata={globaldata}
                section={section}
                number={i}
                empty={empty}
              />
            );
          }
        })}

        <Text
          style={{ marginLeft: 218, paddingTop: 20 }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages} - printed ${new Date().toLocaleString(
              "en-US"
            )}`
          }
          fixed
        />
      </>
    );
  } else {
    return (
      <>
        <Header
          organizationname={globalVars.getValueByFieldName(
            "organizationname",
            globaldata
          )}
          programname={globalVars.getValueByFieldName(
            "programname",
            globaldata
          )}
          reportingperiod={globalVars.getValueByFieldName(
            "reportingdates",
            globaldata
          )}
        />
        <Index structure={responseData} />
        <Metadata
          programname={globalVars.getValueByFieldName(
            "programname",
            globaldata
          )}
          location={globalVars.getValueByFieldName("location", globaldata)}
          classification={globalVars.getValueByFieldName(
            "classification",
            globaldata
          )}
          primarybeneficiary={globalVars.getValueByFieldName(
            "primarybeneficiary",
            globaldata
          )}
          primaryoutcome={globalVars.getValueByFieldName(
            "primaryoutcome",
            globaldata
          )}
        />
        {responseData.map((section, i) => {
          if (i == 0) {
            return;
          } else {
            return (
              <Section globaldata={globaldata} section={section} number={i} />
            );
          }
        })}

        <Text
          style={{ marginLeft: 218, paddingTop: 20 }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages} - printed ${new Date().toLocaleString(
              "en-US"
            )}`
          }
          fixed
        />
      </>
    );
  }
};

export default SurveyPrinter;
