import React from "react";
import { useRifm } from "rifm";
import { useHistory, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import useApiRequest from "../hooks/useApiRequest";
import useDebounce from "../hooks/useDebounce";
import auth from "../helpers/auth";
import igpLogo from "../assets/icons/igp_logo-title.png";
import { ModalBody, ModalButton, ModalParent } from "../elements/Modal";

function TaxId({ value, handleChange, country = null }) {
  const [taxId, setTaxId] = React.useState("");

  const debounceValue = React.useCallback(
    debounce((e) => {
      handleChange(e);
    }, 1000),
    []
  );

  React.useEffect(() => (() => setTaxId(""))(), [country]);
  React.useEffect(() => {
    (() => {
      if (taxId) {
        if (value && taxId.includes("#")) {
          handleChange("");
        } else if (
          (country === "US" || country === "CA") &&
          !taxId.includes("#")
        ) {
          handleChange(taxId);
        } else if (country !== "US" && country !== "CA") {
          debounceValue(taxId);
        }
      }
    })();
  }, [taxId]);

  const parseDigits = (string) => (string.match(/\d+/g) || []).join("");
  const parseLettersAndDigits = (string) =>
    (string.match(/[a-zA-Z\d]+/g) || []).join("");
  const parseLetters = (string) => (string.match(/[a-zA-Z]+/g) || []).join("");
  const formatCA = (string) => {
    const lettersAndDigits = parseLettersAndDigits(string);
    const chars = lettersAndDigits.split("");
    return chars
      .reduce((acc, curr, index) => {
        return index === 9 || index === 11 ? `${acc}${curr}` : `${acc}${curr}`;
      }, "")
      .substr(0, 17);
  };
  const addCAMask = (string) => {
    const digits = parseDigits(string);
    const letters = parseLetters(string);
    const part1 = digits.slice(0, 9).padEnd(9, "#");
    const part2 = letters.slice(0, 2).padEnd(2, "R");
    const part3 = digits.slice(9, 13).padEnd(4, "#");

    return `${part1}${part2}${part3}`;
  };
  const formatUS = (string) => {
    const digits = parseDigits(string);
    const chars = digits.split("");
    return chars
      .reduce(
        (acc, curr, index) =>
          index === 1 ? `${acc}-${curr}` : `${acc}${curr}`,
        ""
      )
      .substr(0, 10);
  };
  const addUSMask = (string) => {
    const digits = parseDigits(string);
    const part1 = digits.slice(0, 2).padEnd(2, "#");
    const part2 = digits.slice(2, 9).padEnd(7, "#");
    return `${part1}-${part2}`;
  };

  const currentFormat = {
    format: country === "US" ? formatUS : country == "CA" ? formatCA : () => {},
    mask: country === "US" ? addUSMask : country == "CA" ? addCAMask : () => {},
  };
  const rifm = useRifm({
    accept: /[\w]/g,
    value: taxId,
    onChange: setTaxId,
    format: currentFormat.format,
    mask: true,
    replace: currentFormat.mask,
  });

  if (country !== "US" && country !== "CA") {
    return (
      <input
        id="taxid"
        name="taxid"
        type="text"
        required
        disabled={!country}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        value={taxId}
        onChange={(e) => setTaxId(e.target.value)}
      />
    );
  }

  return (
    <input
      id="taxid"
      name="taxid"
      type="text"
      required
      disabled={!country}
      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
      value={rifm.value}
      onChange={(e) => rifm.onChange(e)}
    />
  );
}

function Signup(props) {
  const [signupFields, setSignupFields] = React.useState({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    confirmpassword: "",
    orgname: "",
    orglocation: {},
    taxid: "",
  });
  const [errorMessage, setErrorMessage] = React.useState("");
  const [existingOrg, setExistingOrg] = React.useState({
    options: [],
    show: false,
  });
  const [existingOrgSelection, setExistingOrgSelection] = React.useState(null);
  const [successSave, setSuccessSave] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const ref = React.useRef();
  const { setToken } = auth();
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  const { state: countryOptions } = useApiRequest({ reqType: "getLocation" });

  React.useEffect(() => {
    const fetchExistingOrgs = async () => {
      const response =
        signupFields.taxid.length &&
        (await fetchDataOnDemand({
          reqType: "getExistingOrganizations",
          parameters: `?taxid=${signupFields.taxid}`,
        }));
      if (response.length) {
        setExistingOrg({ options: response, show: true });
      } else {
        setExistingOrg({ options: [], show: false });
        if (signupFields.orgname) {
          setSignupFields((prev) => ({ ...prev, orgname: "" }));
          setExistingOrgSelection(null);
        }
      }
    };
    if (signupFields.taxid) {
      fetchExistingOrgs();
    }
  }, [signupFields.taxid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {
      name: signupFields.orgname,
      ein: signupFields.taxid,
      user: {
        firstname: signupFields.firstname,
        lastname: signupFields.lastname,
        email: signupFields.email,
        password: signupFields.password,
      },
      createneworg: existingOrgSelection === "new" ? true : false,
    };
    //   setErrorMessage("");
    const response = await fetchDataOnDemand({
      reqType: "addUser",
      body,
    });
    if (response) {
      setSuccessSave(true);
    }

    //   if (!response.token) {
    //     setErrorMessage("Incorrect username or password");
    //     return;
    //   }
    //   setToken(response.token);
    //   if (props.redirect) {
    //     history.push(props.redirect);
    //   } else {
    //     history.push("/organization");
    //   }
  };
  if (successSave) {
    return (
      <div className="min-h-screen bg-purple-100 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <h2 className=" text-center text-2xl leading-9 font-bold text-purple-800">
                Thank you for registering
              </h2>
              <p className="text-center mt-4 mx-4">
                Please check your inbox to confirm your email address before
                continuing.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-purple-100 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-40" src={igpLogo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-bold text-purple-800">
          Create your account
        </h2>
        <p className="text-center mt-4 mx-4">
          Please complete the information below to create an Impact Genome
          account.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="text-red-500 italic text-xs text-right">
            {errorMessage}
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="my-6 relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm leading-5">
                <span className="px-2 bg-white text-gray-500">
                  Personal Information
                </span>
              </div>
            </div>
            <div>
              <label
                htmlFor="text"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={signupFields.email}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div className="mt-6">
              <label
                htmlFor="first name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  value={signupFields.firstname}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      firstname: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="last name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={signupFields.lastname}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      lastname: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={signupFields.password}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="confirm password"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Confirm Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  ref={ref}
                  value={signupFields.confirmpassword}
                  onChange={(e) => {
                    e.persist();
                    if (e.target.value !== signupFields.password) {
                      e.target.setCustomValidity("Passwords do not match");
                    } else {
                      e.target.setCustomValidity("");
                    }
                    setSignupFields((prev) => ({
                      ...prev,
                      confirmpassword: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="my-6 relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm leading-5">
                <span className="px-2 bg-white text-gray-500">
                  Organization Information
                </span>
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="Organization's Legal Domicile"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Organization's Legal Domicile
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <div>
                  <select
                    id="orglocation"
                    name="orglocation"
                    className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    required
                    // value={signupFields.orglocation}
                    placeholder="Select Country"
                    onChange={(e) => {
                      e.persist();
                      setSignupFields((prev) => ({
                        ...prev,
                        orglocation: JSON.parse(e.target.value),
                        taxid: "",
                      }));
                    }}
                  >
                    <option value="" disabled selected>
                      Select a country
                    </option>
                    {countryOptions.data &&
                      countryOptions.data.map((country, index) => (
                        <React.Fragment key={index}>
                          <option value={JSON.stringify(country)}>
                            {country.name}
                          </option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="Tax Id"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Tax Id
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <div>
                  <TaxId
                    value={signupFields.taxid}
                    handleChange={(e) => {
                      setSignupFields((prev) => ({
                        ...prev,
                        taxid: e,
                      }));
                    }}
                    country={signupFields.orglocation?.code}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex flex-no-wrap justify-between">
                <label
                  htmlFor="Organization Name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Organization Name
                </label>
                <div
                  className="underline cursor-pointer"
                  onClick={() =>
                    setExistingOrg((prev) => ({ ...prev, show: true }))
                  }
                >
                  {existingOrgSelection ? "Edit" : ""}
                </div>
              </div>
              <div className="mt-1 rounded-md shadow-sm">
                <div>
                  <input
                    id="orgname"
                    name="orgname"
                    type="text"
                    value={signupFields.orgname}
                    onChange={(e) => {
                      e.persist();
                      setSignupFields((prev) => ({
                        ...prev,
                        orgname: e.target.value,
                      }));
                    }}
                    required
                    disabled={
                      !signupFields.taxid ||
                      (existingOrgSelection && existingOrgSelection !== "new")
                    }
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="">
                <div className="w-full">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-green-600 transition duration-150 ease-in-out"
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm"></span>
            </div>
          </form>

          <div className="mt-6">
            <hr />

            <div className="mt-6 "></div>
          </div>
          <ModalParent isOpen={existingOrg.show} maxWidth="max-w-md">
            <ModalBody title="This tax ID already exists!" noIcon>
              <div className="text-left sm:w-10/12 mx-auto mt-4 text-gray-800">
                <p className="mb-4">
                  An Organization(s) already exists for the tax ID you entered.
                  Please select one of the following options:
                </p>
                <fieldset>
                  <legend className="sr-only">Organization Selection</legend>

                  {existingOrg.options.length &&
                    existingOrg.options.map((option, index) => (
                      <React.Fragment key={index}>
                        <div className="relative border border-gray-200 p-4 flex">
                          <div className="flex items-center h-5">
                            <input
                              id="settings-option-1"
                              name="privacy_setting"
                              type="radio"
                              checked={
                                existingOrgSelection?.name === option.name
                              }
                              className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out cursor-pointer"
                              onChange={() => {
                                setExistingOrgSelection(option);

                                setSignupFields((prev) => ({
                                  ...prev,
                                  orgname: option.name,
                                }));
                              }}
                            />
                          </div>
                          <label
                            htmlFor="settings-option-1"
                            className="ml-3 flex flex-col cursor-pointer"
                          >
                            {/* On: "text-indigo-900", Off: "text-gray-900" */}
                            <span className="block text-sm leading-5 ">
                              {option.name}
                            </span>
                            {/* On: "text-indigo-700", Off: "text-gray-500" */}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                  {/* On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" */}

                  {/* On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" */}
                  <div className="relative border border-gray-200 rounded-bl-md rounded-br-md p-4 flex">
                    <div className="flex items-center h-5">
                      <input
                        id="settings-option-2"
                        name="privacy_setting"
                        type="radio"
                        className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out cursor-pointer"
                        checked={existingOrgSelection === "new"}
                        onChange={() => {
                          setExistingOrgSelection("new");
                          setSignupFields((prev) => ({
                            ...prev,
                            orgname: "",
                          }));
                        }}
                      />
                    </div>
                    <label
                      htmlFor="settings-option-2"
                      className="ml-3 flex flex-col cursor-pointer"
                    >
                      {/* On: "text-indigo-900", Off: "text-gray-900" */}
                      <span className="block text-sm leading-5">
                        Create a new organization
                      </span>
                      {/* On: "text-indigo-700", Off: "text-gray-500" */}
                    </label>
                  </div>
                </fieldset>
              </div>
            </ModalBody>
            <div className="flex flex-no-wrap justify-end">
              <ModalButton
                text="Save"
                className=""
                color="green"
                disabled={!existingOrgSelection}
                onClick={() => {
                  setExistingOrg((prev) => ({ ...prev, show: false }));
                }}
              />
            </div>
          </ModalParent>
        </div>
      </div>
    </div>
  );
}

export default Signup;
